input {
  &:focus, &:active {
    box-shadow: none;
  }
}

.pi {
  margin-top: 0;
  transform: translateY(-50%);
}

[pInputText] {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
}

.error-message {
  font-size: 13px;
  color: var(--color-error);
  line-height: 1;
  margin-top: 8px;
  font-weight: 400;
  display: none;
}

.ng-invalid {
  input[pInputText] {
    border-color: var(--color-error);
  }

  .error-message {
    display: block;
  }
}

a.btn-click {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;

  &.disabled {
    color: var(--color-disabled);
    pointer-events: none;
  }
}

.input-group {
  display: flex;
  gap: 12px;
  align-items: baseline;

  &.ng-invalid {
    .input-label {
      margin-bottom: 21px;
    }
  }

  .input {
    flex: 1;
  }

  .input-hint {
    margin-top: 8px;
    font-size: 13px;
    color: var(--color-sub-text);
    line-height: 19px;
  }

  .input-label {
    width: 60px;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-common-text);

    &.required {
      &::after {
        content: '*';
        color: var(--color-error);
      }
    }
  }


}


