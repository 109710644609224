$sw-sm-knob-size: 20px !default;
$sw-md-knob-size: 26px !default;
$sw-lg-knob-size: 40px !default;

$sw-sm-font-size: 9px !default;
$sw-md-font-size: 16px !default;
$sw-lg-font-size: 16px !default;

$sw-sm-min-width: 33px;
$sw-md-min-width: 50px;
$sw-lg-min-width: 60px;

%small-switch-min-width {
	min-width: $sw-sm-min-width;
}
%medium-switch-min-width {
	min-width: $sw-md-min-width;
}
%large-switch-min-width {
	min-width: $sw-lg-min-width;
}

%small-label-font-size {
	font-size: $sw-sm-font-size;
}
%medium-label-font-size {
	font-size: $sw-md-font-size;
}
%large-label-font-size {
	font-size: $sw-lg-font-size;
}

@mixin config-switch-sizes($prefix, $sizes...) {
	@each $i in $sizes {
		&.#{$prefix}#{nth($i, 1)} {
			// min-width via placeholder
			@extend %#{ nth($i, 1) }-switch-min-width;
			height: nth($i, 2);
			border-radius: nth($i, 2);
			small {
				width: nth($i, 2);
				height: nth($i, 2);
				right: calc(100% - #{nth($i, 2)});
			}
			> .switch-pane {
				> span {
					// font-size via placeholder
					@extend %#{ nth($i, 1) }-label-font-size;
					line-height: nth($i, 2);
				}
				.switch-label {
					&-checked {
						padding-right: nth($i, 2) + 5px;
						padding-left: calc((nth($i, 2) / 3) * 1.5);
					}
					&-unchecked {
						padding-left: nth($i, 2) + 5px;
						padding-right: calc((nth($i, 2) / 3) * 1.5);
					}
				}
			}
		}
	}
}

button.switch {
	position: relative;
	display: inline-block;
	box-sizing: content-box;
	padding: 0;
	margin: 0;
	border: 0;
	cursor: pointer;
	box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
	transition: 0.3s ease-out all;
	-webkit-transition: 0.3s ease-out all;
	white-space: nowrap;
	height: 26px !important;
	background-color: #ccc;
	background-image: url("/assets/img/tick.svg") !important;
	background-repeat: no-repeat !important;
	background-position: 2px center !important;

	&:focus {
		outline: 0;
	}

	small {
		border-radius: 100%;
		box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 0;
		right: calc(100% - 30px);
		height: 26px !important;
		width: 26px !important;
		transition: 0.3s ease-out all;
		-webkit-transition: 0.3s ease-out all;
		background-color: #fff;
	}

	// populate &.small, &.medium, &.large classes
	@include config-switch-sizes(
		"switch-",
		"small" $sw-sm-knob-size,
		"medium" $sw-md-knob-size,
		"large" $sw-lg-knob-size
	);

	&.checked {
		background: #a0d943;

		&.checked small {
			right: 0;
			left: auto;
		}
		.switch-pane {
			top: 0;
			.switch-label {
				&-checked {
					opacity: 1;
				}
				&-unchecked {
					opacity: 0;
				}
			}
		}
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.switch-pane {
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 100%;
		justify-content: flex-start;
		align-items: center;
		top: -100%;
		position: relative;
		pointer-events: none;
		> span {
			display: block;
			min-height: 100%;
		}

		.switch-label {
			&-checked {
				opacity: 0;
			}
			&-unchecked {
				opacity: 1;
			}
		}
	}
}

ui-switch.login-main {
	button.switch.checked {
		background: #4b2488;
	}
}

ui-switch.useCssVar {
	button.switch{
		transition: 0.3s ease-out background-color;
		-webkit-transition: 0.3s ease-out background-color;
	}
	button.switch.checked {
		background: #4b2488;
		background: var(--section-maincolor, #4b2488);
	}
}
