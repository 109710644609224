.drag-scroll-wrapper{
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.drag-scroll-content{
  overflow: hidden !important;
  width: 100% !important;
  height: 100% !important;
}

.drag-scroll-content::-webkit-scrollbar{ //hide scrollbar for dragScroll
  display: none !important;
}
