@import "variables";

.ag-theme-alpine {
	@extend %rainBowFont;
}

ag-grid-angular {
	--ag-background-color: var(--aggrid-bgcolor);
	--ag-odd-row-background-color: var(--aggrid-bgcolor);
}

ag-grid-angular {

	&.no-scroll-x .ag-body-horizontal-scroll {
		display: none;
	}

	.ag-body-viewport {
		::-webkit-scrollbar:horizontal {
			display: none;
			width: 0;
		}
	}

	.ag-body-horizontal-scroll {
		// ag grid 水平 scroll bar 背境
		height: 0px !important;
		min-height: 0px !important;
		max-height: 0px !important;

		.ag-body-horizontal-scroll-viewport {
			// ag grid 水平 scroll bar
			height: 20px !important;
			min-height: 20px !important;
			max-height: 20px !important;
			margin-top: -10px;
		}

		::-webkit-scrollbar {
			// thumb
			width: 15px;
			height: 20px;
			-webkit-appearance: none;
			background-color: transparent;

			&-thumb {
				background-color: rgba(0, 0, 0, 0.4);
				background-color: var(--scrollbar-color, rgba(0, 0, 0, 0.4));
				width: 10px;
				height: 10px;
				border-radius: 10px;
			}
		}
	}

	::-webkit-scrollbar {
		width: 15px;
		height: 6px;
		-webkit-appearance: none;
		background-color: transparent;

		&-thumb {
			background-color: rgba(0, 0, 0, 0.4);
			background-color: var(--scrollbar-color, rgba(0, 0, 0, 0.4));
			width: 6px;
			height: 6px;
		}
	}
}

// 排行榜ag-grid式樣
ag-grid-angular.useCssVar {
	div.ag-root-wrapper.ag-layout-normal, div.ag-root-wrapper.ag-layout-auto-height {
		transition: border-color 0.25s, background-color 0.25s;
		background-color: var(--aggrid-bgcolor);
		border-color: var(--aggrid-linecolor);

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					transition: background-color 0.25s;
					background-color: var(--aggrid-headerbgcolor);
					border-color: var(--aggrid-linecolor);

					.ag-pinned-left-header, .ag-pinned-right-header {
						border-color: var(--aggrid-linecolor);

						.ag-header-row {
							color: var(--aggrid-headertextcolor);
						}
					}

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								color: var(--aggrid-headertextcolor);

								.ag-header-cell {
									border-color: var(--aggrid-linecolor);

									.ag-header-cell-resize {
										&:after {
											background-color: #cccccc;
											background-color: var(--aggrid-bordercolor,
													#cccccc);
										}
									}

									.ag-floating-filter-body {
										.ag-floating-filter-input {
											.ag-labeled {
												.ag-input-wrapper {
													.ag-input-field-input {
														color: #333;
														color: var(--common-textcolor);
														background-color: #ffffff;
														background-color: var(--aggrid-filterinputbgcolor,
																#ffffff);
														border-color: #babfc7;
														border-color: var(--aggrid-filterinputbordercolor,
																#babfc7);

														&:disabled {
															color: #999;
															color: var(--common-textcolor3);
															background-color: rgba(255,
																	255,
																	255,
																	0.5);
															background-color: var(--aggrid-filterinputbgcolor-disabled,
																	rgba(255,
																		255,
																		255,
																		0.5));
                                                            border-color: var(--aggrid-filterinputbordercolor-disabled);
														}
													}
												}
											}
										}
									}

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											.ag-icon {
												font-size: var(--aggrid-filtericonsize,
														22px);
												color: var(--aggrid-filtericoncolor,
														#999999);
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-body-viewport {
					.ag-row {
						transition: background-color 0.25s;
						background-color: var(--aggrid-bgcolor);
						color: var(--aggrid-textcolor);
						border-color: var(--aggrid-linecolor);
						&.ag-row-hover {
							--aggrid-bgcolor: var(--aggrid-row-mouseover-bgcolor);
						}

						.ag-cell {
							border-color: var(--aggrid-linecolor) !important;
							border-width: 0px;

							&.ag-cell-inline-editing {
								height: 100%;
								padding: 0px;
								box-shadow: none;
								border-radius: initial;
								background-color: inherit;

								.ag-cell-edit-wrapper {
									.ag-cell-editor {
										.ag-text-field-input-wrapper {
											input {
												border: none;
												background-color: transparent;
												color: var(--common-textcolor);

												&:focus {
													border-radius: initial;
												}
											}
										}
									}
								}
							}

							.ag-cell-wrapper.ag-row-group{
								align-items: center;

								.ag-icon{ //arrow icon
									font-size: 22px;
									line-height: 22px;
									color: #999;
								}
							}

							.ag-group-value{
								max-height: 100%;
							}
						}
						
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
		}

		.ag-popup {
			.ag-popup-editor {
				border: none;
				background-color: transparent;

				.ag-large-text {
					.ag-large-text-input {
						padding: 0px;

						.ag-wrapper {
							textarea.ag-input-field-input {
								border: none;
								box-shadow: none !important;
								color: #333333;
								color: var(--common-textcolor);
								background-color: #ffffff;
								background-color: var(--common-bgcolor);
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.userAndGroupModule {
	div.ag-root-wrapper.ag-layout-normal {
		overflow: initial;

		div.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							color: var(--list-headerfontcolor);

							.ag-header-cell-text {
								font-weight: normal;
								font-size: 15px;
							}
						}
					}

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								color: var(--list-headerfontcolor);
								font-weight: normal;

								.ag-header-cell {
									padding-left: 15px;
									padding-right: 15px;

									.ag-header-cell-label {
										.ag-header-cell-text {
											font-size: 14px;
										}
									}

									.ag-header-cell-resize {
										&:after {
											background-color: #cccccc;
										}
									}

									.ag-floating-filter-button {
										margin-left: 5px;

										.ag-floating-filter-button-button {
											.ag-icon {
												//font-size: 20px;
												//color: #666666;
											}
										}
									}

									.ag-floating-filter-body {
										.ag-floating-filter-input {
											.ag-labeled {
												.ag-wrapper {
													.ag-input-field-input {}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					.ag-center-cols-clipper {
						.ag-center-cols-viewport {
							.ag-center-cols-container {
								.ag-row {
									//color: #333333;

									&.ag-row-hover {
										background-color: #efedf7;
										background-color: var(--aggrid-row-mouseover-bgcolor,
												#efedf7);
									}

									&.ag-row-selected {
										background-color: #e8e3f1;
										background-color: var(--aggrid-row-selected-bgcolor,
												#e6e4ee);
									}

									&.archived:not(.ag-row-selected),
									&.deleted:not(.ag-row-selected) {
										background-color: #f2f2f2;
										background-color: var(--aggrid-row-archived-bgcolor,
												#f2f2f2);
									}
								}
							}
						}
					}
				}
			}

			.ag-side-bar {
				.ag-tool-panel-wrapper {
					.ag-column-panel {
						.ag-column-select {
							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: #684ccc;
															color: var(--tickbox-selectedcolor,
																	#684ccc);
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.ag-popup {
			.ag-tabs {
				.ag-tabs-body {
					.ag-filter {
						.ag-filter-wrapper {
							.ag-filter-body-wrapper {
								.ag-set-filter {
									.ag-set-filter-list {
										.ag-virtual-list-viewport {
											.ag-virtual-list-container {
												.ag-virtual-list-item {
													.ag-set-filter-item {
														.ag-set-filter-item-checkbox {
															.ag-checkbox-input-wrapper {
																&.ag-checked::after {
																	color: #473779;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.profileModule {
	.ag-root-wrapper {
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {

					//background-color: #ededed;
					//border-color: #dedede;
					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border-right: 1px solid #dedede;
									padding: 0px 10px;

									.ag-header-cell-resize {
										&:after {
											width: 1px;
											opacity: 0;
										}
									}

									&.iconBtn {
										padding: 0px;
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 14px;
												//color: #000000;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					//overflow-y: hidden;

					.ag-row {

						//background-color: #ffffff;
						&:last-child {
							border-bottom-width: 0px;
						}

						.ag-cell {
							line-height: 30px;
							padding: 0px 10px;
							border: none;
							border-right: 1px solid #dedede;
							border-width: 1px !important;

							&.iconBtn {
								padding: 0px;
								display: flex;
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.questionBankModule {
	.ag-root-wrapper {
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {

				.ag-body-viewport {
					.ag-row {
						.ag-cell {
							&.iconBtn {
								padding: 0px;
								display: flex;
								justify-content: center;
							}

							&.editDisable {
								color: var(--aggrid-headerbgcolor);
								background-color: var(--aggrid-headerbgcolor);
							}

							&.linkedQuestion {
								color: var(--section-maincolor);
							}

							&.notSameSchool {
								color: #999;
							}

							&.asset {
								color: #008de2;
							}
						}
					}
				}
			}
		}
		.ag-body-horizontal-scroll .ag-body-horizontal-scroll-viewport {margin-top: -42px;}
	}
}
ag-grid-angular.forGrid.questionBankModule .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-floating-bottom .ag-row .ag-cell{border-width: 0px !important;}
ag-grid-angular.forGrid.questionBankModule .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-body-viewport .ag-row:last-child{border-bottom-width: 1px;}

ag-grid-angular.courseModule {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: #fff;
					background-color: var(--aggrid-headerbgcolor, #fff);

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
												//color: #000000;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					
					.ag-center-cols-viewport {

						.ag-center-cols-container {
							// width: 100% !important;

							.ag-row {
								border-color: var(--aggrid-linecolor) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: var(--section-maincolor10pt, --section-maincolor, #684ccc1a);
								}

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33);
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.evaluationFormModule {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: #fff;
					background-color: var(--common-bgcolor, #fff) !important;

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
												//color: #000000;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					
					.ag-center-cols-viewport {

						.ag-center-cols-container {
							// width: 100% !important;

							.ag-row {
								border-color: var(--aggrid-linecolor) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: var(--section-maincolor10pt, --section-maincolor, #684ccc1a);
								}

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33);
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.schoolSettingPeriodModule {
	.ag-root-wrapper {
		overflow: overlay !important;
		width: 100%;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border-right: 1px solid #dedede;
									&:last-child {
										border-right: 0px;
									}
									padding: 0px 10px;

									.ag-header-cell-resize {
										&:after {
											width: 1px;
											opacity: 0;
										}
									}

									&.iconBtn {
										padding: 0px;
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 14px;
												color: var(--aggrid-headertextcolor, #666);

											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					.ag-row {
						&:last-child {
							border-bottom-width: 0px;
						}

						.ag-cell {
							line-height: 30px;
							padding: 0px 10px;
							border: none;
							border-right: 1px solid #dedede;
							&:last-child {
								border-right: 0px;
							}
							border-width: 1px !important;

							&.iconBtn {
								padding: 0px;
								display: flex;
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.schoolSettingDefaultPublishedPeriod {
	.ag-root-wrapper {
		overflow: overlay !important;
		width: 100%;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border-right: 1px solid #dedede;
									&:last-child {
										border-right: 0px;
									}
									padding: 0px 10px;

									.ag-header-cell-resize {
										&:after {
											width: 1px;
											opacity: 0;
										}
									}

									&.iconBtn {
										padding: 0px;
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 14px;
												color: var(--aggrid-headertextcolor, #666);

											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					.ag-row {
						&:last-child {
							border-bottom-width: 0px;
						}

						.ag-cell {
							line-height: 30px;
							padding: 0px 10px;
							border: none;
							border-right: 1px solid #dedede;
							&:last-child {
								border-right: 0px;
							}
							border-width: 1px !important;

							&.iconBtn {
								padding: 0px;
								display: flex;
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.schoolSettingVenueModule {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: #fff;
					background-color: var(--common-bgcolor, #ffffff);

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								background-color: var(
									--common-bgcolor,
									#ffffff
								);

								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(
												--aggrid-bordercolor
											);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
												color: var(--list-headerfontcolor, #666);

											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;

					.ag-center-cols-viewport {
						.ag-center-cols-container {

							.ag-row {
								border-color: var(
									--aggrid-linecolor
								) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover {
									background-color: var(
										--section-maincolor10pt,
										--section-maincolor,
										#684ccc1a
									);
								}

								&.ag-row-selected {
									background-color: var(
										--section-maincolor20pt,
										--section-maincolor,
										#684ccc33
									);
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;
									

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}
								}

								.colored-column{
									font-weight: bold;
									color: var(--section-maincolor, #724AB5);
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.schoolSettingSubjectModule {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: #fff;
					background-color: var(--common-bgcolor, #ffffff) !important;
					transition: none !important;
					

					.ag-header-viewport {
						.ag-header-container {
							transform: none !important;
							.ag-header-row {
								background-color: var(
									--common-bgcolor,
									#ffffff
								);

								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;
									
									&.iconBtn {
										padding: 0px;
									}

									
									
									.ag-header-cell-resize {
										width: 20px;

										&::after {
											opacity: 1 !important;
											background-color: var(
												--aggrid-bordercolor
											);
											
										}
										
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
												color: var(--list-headerfontcolor, #666);

											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;

					.ag-center-cols-viewport {
						.ag-center-cols-container {

							.ag-row {
								border-color: var(
									--aggrid-linecolor
								) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover {
									background-color: var(
										--section-maincolor10pt,
										--section-maincolor,
										#684ccc1a
									);
								}

								&.ag-row-selected {
									background-color: var(
										--section-maincolor20pt,
										--section-maincolor,
										#684ccc33
									);
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;
									

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}
								}

								.colored-column{
									font-weight: bold;
									color: var(--section-maincolor, #724AB5);
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.courseModule-enroll {
	.ag-root-wrapper {
		overflow: initial;
		border: 0;
		overflow: hidden;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					.ag-floating-filter-button {
						margin-left: 5px;
						margin-right: 15px;

						.ag-floating-filter-button-button {
							width: 22px;
						}

						.ag-icon-filter {
							font-weight: 400;
							font-size: 22px;
							font-size: var(--filtericonsize, 22px);
							color: #999;
							color: var(--aggrid-filtericoncolor,
									#999);
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						background-color: #ededed;
						background-color: var(--aggrid-headerbgcolor, #ededed);

						.ag-header-cell {
							border-top-color: var(--aggrid-linecolor, #ccc);
							padding: 0px 10px;
							font-size: 15px;

							.ag-input-field-input {
								color: #333;
								color: var(--common-textcolor);
								background-color: #fff;
								background-color: var(--aggrid-filterinputbgcolor,
										#fff);
								border-color: #babfc7;
								border-color: var(--aggrid-filterinputbordercolor,
										#babfc7);
							}

							.ag-icon-filter {
								font-weight: 400;
								font-size: 22px;
								font-size: var(--filtericonsize, 22px);
								color: #babfc7;
								color: var(--aggrid-filtericoncolor, #999);
							}

							.ag-header-cell-text {
								font-weight: normal;
							}
						}
					}

					.ag-header-viewport {
						background-color: #ededed;
						background-color: var(--aggrid-headerbgcolor, #ededed);

						.ag-header-row {
							.ag-header-cell {
								font-size: 15px;
								padding: 0px 10px;

								.ag-header-cell-resize {
									width: 30px;

									&::after {
										opacity: 1 !important;
										background-color: var(--aggrid-bordercolor);
									}
								}

								.ag-header-cell-label {
									.ag-header-cell-text {
										font-size: 15px;
										font-weight: normal;
									}

									.ag-header-label-icon {
										font-size: 22px;
										font-size: var(--filtericonsize, 22px);
										font-weight: 400;
										margin-top: 2px;
									}
								}
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 40px;

					.ag-pinned-left-cols-container {
						.ag-row {
							border-right: 1px solid#ccc;
							border-right: 1px solid var(--aggrid-linecolor, #ccc);

							.ag-cell {
								display: inline-flex;
								align-items: center;
								padding: 0px 10px;
							}
						}
					}

					.ag-center-cols-clipper {
						.ag-center-cols-viewport {

							.ag-center-cols-container {
								.ag-row {

									.ag-row-hover{
										background-color: var(--section-maincolor10pt, --section-maincolor, #684ccc1a) !important;
									}
							
									.ag-row-selected{
										background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33) !important;
									}

									.ag-cell {
										display: inline-flex;
										align-items: center;
										color: #333;
										color: var(--color-333-fff, #333);
										padding: 0px 10px;
									}
								}
							}
						}
					}
				}

				.ag-floating-bottom {
					border-top: 1px solid #ccc;
					border-top: 1px solid var(--aggrid-linecolor, #ccc);

					.ag-row {
						border-bottom: 0;
						background-color: #fff;
						background-color: var(--aggrid-bgcolor, #fff);

						.ag-cell {
							display: inline-flex;
							align-items: center;
							color: #333;
							color: var(--color-333-fff, #333);
							border-right: 0;
							padding: 0px 10px;

							&[col-id="student_name"] {
								justify-content: center;
							}
						}
					}
				}

				.ag-body-horizontal-scroll {
					.ag-horizontal-left-spacer {
						border-right-color: #ccc;
						border-right-color: var(--aggrid-linecolor, #ccc);
					}
				}
			}

			.ag-side-bar {
				.ag-tool-panel-wrapper {
					.ag-column-panel {
						.ag-column-select {
							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: #473779;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.ag-popup {
			.ag-tabs {
				.ag-tabs-header {
					.ag-icon-filter {
						font-size: 22px;
						font-size: var(--aggrid-filtericonsize, 22px);
						font-weight: 400;
						color: #999;
						color: var(--aggrid-filtericoncolor, #999);
					}
				}

				.ag-tabs-body {
					.ag-filter {
						.ag-filter-wrapper {
							.ag-filter-body-wrapper {
								.ag-set-filter {
									.ag-set-filter-list {
										.ag-virtual-list-viewport {
											.ag-virtual-list-container {
												.ag-virtual-list-item {
													.ag-set-filter-item {
														.ag-set-filter-item-checkbox {
															.ag-checkbox-input-wrapper {
																&.ag-checked::after {
																	color: #473779;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
ag-grid-angular.library {
	div.ag-root-wrapper.ag-layout-normal, div.ag-root-wrapper.ag-layout-auto-height {
		transition: border-color 0.25s, background-color 0.25s;
		background-color: var(--aggrid-bgcolor);
        border: none !important;
		border-color: var(--aggrid-linecolor);

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					transition: background-color 0.25s;
					background-color: var(--aggrid-bgcolor);
					border-color: var(--aggrid-linecolor);
                    font-size: 15px;

					.ag-pinned-left-header, .ag-pinned-right-header {
						border-color: var(--aggrid-linecolor);

						.ag-header-row {
							color: var(--aggrid-headertextcolor2);
						}
					}

					.ag-header-viewport {
						.ag-header-container {
                            background-color: var(--aggrid-headerbgcolor);
							.ag-header-row {
								color: var(--aggrid-headertextcolor2);

								.ag-header-cell {
									border-color: var(--aggrid-linecolor);
                                    padding: 10px ;

									.ag-header-cell-resize {
                                        width: 30px;
										&:after {
											background-color: var(--aggrid-bordercolor,#cccccc);
										}
									}

									.ag-floating-filter-body {
										.ag-floating-filter-input {
											.ag-labeled {
												.ag-input-wrapper {
													.ag-input-field-input {
														color: #333;
														color: var(--common-textcolor);
														background-color: #ffffff;
														background-color: var(--aggrid-filterinputbgcolor,
																#ffffff);
														border-color: #babfc7;
														border-color: var(--aggrid-filterinputbordercolor,
																#babfc7);

														&:disabled {
															// background-color: rgba(255,
															// 		255,
															// 		255,
															// 		0.5);
															color: #999;
															background-color: var(--common-textcolor3);
															background-color: var(--aggrid-filterinputbgcolor-disabled,
																rgba(255,255,255,0.5));
                                                            border-color: var(--aggrid-filterinputbordercolor-disabled,
                                                            rgba(204,204,204,0.4));
														}
													}
												}
											}
										}
									}

									.ag-floating-filter-button {
                                        margin-left: 5px;
                                        margin-right: 15px;
										.ag-floating-filter-button-button {
											.ag-icon {
												cursor: pointer;
												font-size: var(--aggrid-filtericonsize,
														22px);
												color: var(--aggrid-filtericoncolor,
														#999999);
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-body-viewport {
                    .archived_book{
                        color: #999999 !important;
                        
                    }
					.ag-row {
						transition: background-color 0.25s;
						background-color: var(--aggrid-bgcolor);
						color: var(--common-textcolor);
						border-color: var(--aggrid-linecolor);

                        &.ag-row-hover {
                            background-color: #1F84921A;
							// background-color: rgba(var(--section-maincolor), 0.1)
                            // background-color: red;
                        }
                        &.ag-row-hover .ag-column-hover{
                            border: solid 1px ;
                            border-color: #1f859255 ;
                        }

						.ag-cell {
							border-color: #1f859255 ;
							border-width: 0px;
                            padding: 5px 10px;
                            
							&.ag-cell-inline-editing {
								height: 100%;
								padding: 0px;
								box-shadow: none;
								border-radius: initial;
								background-color: inherit;


								.ag-cell-edit-wrapper {
									.ag-cell-editor {
										.ag-text-field-input-wrapper {
											input {
												border: none;
												background-color: transparent;
												color: var(--common-textcolor);

												&:focus {
													border-radius: initial;
												}
											}
										}
									}
								}
							}

							.ag-cell-wrapper.ag-row-group{
								align-items: center;

								.ag-icon{ //arrow icon
									font-size: 22px;
									line-height: 22px;
									color: #999;
								}
							}

							.ag-group-value{
								max-height: 100%;
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
		}

		.ag-popup {
			.ag-popup-editor {
				border: none;
				background-color: transparent;

				.ag-large-text {
					.ag-large-text-input {
						padding: 0px;

						.ag-wrapper {
							textarea.ag-input-field-input {
								border: none;
								box-shadow: none !important;
								color: #333333;
								color: var(--common-textcolor);
								background-color: #ffffff;
								background-color: var(--common-bgcolor);
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.schoolSettingRoleModule {
	.ag-root-wrapper {
	  border: 0;
	  overflow: initial;
  
	  .ag-root-wrapper-body {
		.ag-root {
		  .ag-header {
			background-color: #fff;
			background-color: var(--common-bgcolor, #ffffff) !important;
			transition: none !important;
  
			.ag-header-viewport {
			  .ag-header-container {
				transform: none !important;
				.ag-header-row {
				  background-color: var(--common-bgcolor, #ffffff);
  
				  &:last-child {
					border-bottom-width: 0px;
				  }
  
				  .ag-header-cell {
					border: 0;
					padding: 0px 10px;
  
					&.iconBtn {
					  padding: 0px;
					}
  
					.ag-header-cell-resize {
					  width: 20px;
  
					  &::after {
						opacity: 1 !important;
						background-color: var(--aggrid-bordercolor);
					  }
					}
  
					.ag-cell-label-container {
					  .ag-header-cell-label {
						.ag-header-cell-text {
						  font-size: 15px;
						  font-weight: normal;
						  color: var(--list-headerfontcolor, #666);
						}
					  }
					}
				  }
				}
			  }
			}
  
			.ag-pinned-left-header {
			  .ag-header-row {
				.ag-header-cell {
				  padding: 0px 10px;
				}
			  }
			}
		  }
  
		  .ag-body-viewport {
			padding-bottom: 50px;
  
			.ag-center-cols-viewport {
			  .ag-center-cols-container {
				.ag-row {
				  border-color: var(--aggrid-linecolor) !important;
				  width: 100%;
  
				  &::after {
					content: '';
					display: inline-flex;
					position: absolute;
				  }
  
				  &.ag-row-hover {
					background-color: var(
					  --section-maincolor10pt,
					  --section-maincolor,
					  #684ccc1a
					);
				  }
  
				  &.ag-row-selected {
					background-color: var(
					  --section-maincolor20pt,
					  --section-maincolor,
					  #684ccc33
					);
				  }
  
				  .ag-cell {
					display: inline-flex;
					flex: 0 0 auto;
					flex-direction: row;
					align-items: center;
					padding: 0px 10px;
					color: #333;
					color: var(--common-textcolor, #333);
					border: none;
  
					&.cell-button {
					  padding: 0;
					  justify-content: center;
					  align-items: center;
					}
				  }
  
				  .colored-column {
					font-weight: bold;
					color: var(--section-maincolor, #724ab5);
				  }
				}
			  }
			}
		  }
		}
	  }
	}
  }

ag-grid-angular.rankingModule {
	&.rankingStar div.ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-header {
		background-color: var(--aggrid-filterheaderbgcolor, #fff);
	}
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-popup {
			color: var(--aggrid-headertextcolor);
			.ag-list, .ag-select .ag-picker-field-wrapper, input[class^='ag-'] {
				background-color: var(--aggrid-filterinputbgcolor);
				color: var(--common-textcolor);
			}
		}

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: var(--aggrid-headerbgcolor, #fff);

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
											}
										}
									}

									.ag-floating-filter-button {
										margin-left: 5px;
										margin-right: 15px;
									}

								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					
					.ag-center-cols-viewport {

						.ag-center-cols-container {

							.ag-row {
								border-color: var(--list-linecolor) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: var(--section-maincolor-hoverRow)!important
								}

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor);
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;
									font-weight: bold;

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									.rank {
										display: block;
										width: 25px;
										height: 25px;
										border-radius: 50%;
										font-size: 14px;
										@extend %flex;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										margin: 0 auto;
										text-align: center;
										line-height: 25px;

										&-1 {
											background: radial-gradient(circle at 33% 33%,#fea32a,#fff700);
											color: #fff;
										}
										&-2 {
											background: radial-gradient(circle at 33% 33%,#a0a0a0,#d8d8d8);
											color: #fff;
										}
										&-3 {
											background: radial-gradient(circle at 33% 33%,#832f09,#b70);
											color: #fff;
										}
									}

									.score {
										border-radius: 5px;
										@extend %flex;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										height: 26px;
										width: 50px;
										font-size: 15px;
										&.green {
											background-color: #7FC42D;
											color: #fff;
										}
										&.red {
											background-color: #EE4864;
											color: #fff;
										}
									}

									&.ch, &.noBold {
										font-weight: normal;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.redeemModule {

	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: var(--aggrid-headerbgcolor, #fff);

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
											}
										}
									}

									.ag-floating-filter-button {
										margin-left: 5px;
										margin-right: 15px;
									}

								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					
					.ag-center-cols-viewport {

						.ag-center-cols-container {

							.ag-row {
								border-color: var(--aggrid-linecolor) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									photo-renderer {
										height: 100%;
									}

									&.has-starIcon {
										> span {
											display: flex;
											align-items: center;
											font-weight: bold;
										}
										.positive {
											color: #FF6600;
										}
										.negative {
											color: var(--common-textcolor2);
										}
										.star {
											margin-right: 5px;
											width: 25px; 
											height: 25px; 
											background: url('/assets/img/icon_star_report.svg') no-repeat center / contain;
										}
									}

								}
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.redeemModule {

	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					background-color: var(--aggrid-headerbgcolor, #fff);

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding: 0px 10px;

									&.iconBtn {
										padding: 0px;
									}

									.ag-header-cell-resize {
										width: 30px;

										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 15px;
												font-weight: normal;
											}
										}
									}

									.ag-floating-filter-button {
										margin-left: 5px;
										margin-right: 15px;
									}

								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport {
					padding-bottom: 50px;
					
					.ag-center-cols-viewport {

						.ag-center-cols-container {

							.ag-row {
								border-color: var(--aggrid-linecolor) !important;
								width: 100%;

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding: 0px 10px;
									color: #333;
									color: var(--common-textcolor, #333);
									border: none;

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									photo-renderer {
										height: 100%;
									}

									&.has-starIcon {
										> span {
											display: flex;
											align-items: center;
											font-weight: bold;
										}
										.positive {
											color: #FF6600;
										}
										.negative {
											color: var(--common-textcolor2);
										}
										.star {
											margin-right: 5px;
											width: 25px; 
											height: 25px; 
											background: url('/assets/img/icon_star_report.svg') no-repeat center / contain;
										}
									}

									&.has-gemIcon {
										> span {
											display: flex;
											align-items: center;
											font-weight: bold;
										}
										.positive {
											color: #318DD7;
										}
										.negative {
											color: var(--common-textcolor2);
										}
										.ruby {
											margin-right: 5px;
											width: 25px; 
											height: 25px; 
											background: url('/assets/img/redeem/icon_gem_ruby (1).svg') no-repeat center / contain;
										}
										.emberald {
											margin-right: 5px;
											width: 25px; 
											height: 25px; 
											background-image: url('/assets/img/redeem/icon_gem_emberald (1).svg');
											background-repeat: no-repeat;
											background-position: center;
											background-size: contain;
											   
										}
										.crystal {
											margin-right: 5px;
											width: 25px; 
											height: 25px; 
											background-image: url('/assets/img/redeem/icon_gem_crystal.svg');
											background-repeat: no-repeat;
											background-position: center;
											background-size: contain;

											   
										}
									}

									.status {
										border-radius: 5px;
										color: #fff;
										background-color: #7fc42d;
										overflow: hidden;
										font-weight: bold;
										height: 30px;
										font-size: 15px;
										line-height: 30px;
										text-align: center;
										display: block;
										width: 120px;

										&__pending {background-color: #FFBC56;}
										&__redeemed {background-color: #7fc42d;}
										&__cancelled {background-color: #EE4865;}
									}				

								}
							}
						}
					}
				}
			}
		}
	}

	&.hasLoadMore {
		.ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-body-viewport {
			padding-bottom: 75px;
		}
	}
}

ag-grid-angular.forGrid {
	.ag-root-wrapper {
		transition: border-color 0.25s, background-color 0.25s;
		background-color: var(--aggrid-bgcolor);
		border-color: var(--aggrid-linecolor);
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					transition: background-color 0.25s;
					background-color: var(--aggrid-headerbgcolor);
					border-color: var(--aggrid-linecolor);

					.ag-pinned-left-header, .ag-pinned-right-header {
						border-color: var(--aggrid-linecolor);

						.ag-header-row {
							color: var(--aggrid-headertextcolor);
							font-size: (var(--aggrid-headertextsize));
						}
					}

					.ag-header-viewport {
						.ag-header-container {
							.ag-header-row {
								color: var(--aggrid-headertextcolor);

								.ag-header-cell {
									border-color: var(--aggrid-linecolor);
									border-right: 1px solid #dedede;
									padding: 0px 10px;

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											width: var(--aggrid-filtericonsize);
											height: var(--aggrid-filtericonsize);
											cursor: pointer;

											.ag-icon-filter {
												color: var(--aggrid-filtericoncolor);
												font-size: var(--aggrid-filtericonsize);
											}
										}
									}

									.ag-header-cell-resize {
										&:after {
											background-color: #cccccc;
											background-color: var(--aggrid-bordercolor, #cccccc);
										}
									}

									&.iconBtn {
										padding: 0px;
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: 14px;
											}
										}
									}

									.ag-floating-filter-body {
										.ag-floating-filter-input {
											.ag-labeled {
												.ag-input-wrapper {
													.ag-input-field-input {
														color: #333;
														color: var(--aggrid-filterinputbgcolor);
														background-color: #ffffff;
														background-color: var(--aggrid-filterinputbgcolor,
																#ffffff);
														border-color: #babfc7;
														border-color: var(--aggrid-filterinputbordercolor,
																#babfc7);

														&:disabled {
															background-color: rgba(255,
																	255,
																	255,
																	0.5);
															background-color: var(--aggrid-filterinputbgcolor-disabled,
																	rgba(255,
																		255,
																		255,
																		0.5));
                                                            border-color: var(--aggrid-filterinputbordercolor-disabled);
														}
													}
												}
											}
										}
									}

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											.ag-icon {
												font-size: var(--aggrid-filtericonsize,
														22px);
												color: var(--aggrid-filtericoncolor,
														#999999);
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell {
								padding: 0px 10px;
							}
						}
					}
				}

				.ag-body-viewport,.ag-floating-bottom {
					.ag-row {
						transition: background-color 0.25s;
						background-color: var(--aggrid-bgcolor);
						color: var(--aggrid-textcolor);
						border-color: var(--aggrid-linecolor);
						font-size: var(--aggrid-textsize);
						height: var(--aggrid-rowheight);

						&:last-child {
							border-bottom-width: 0px;
						}

						.ag-cell {
							border-color: var(--aggrid-linecolor) !important;
							line-height: 30px;
							padding: 0px 10px;
							border: none;
							border-right: 1px solid #dedede;
							border-width: 1px !important;

							&.iconBtn {
								padding: 0px;
								display: flex;
								justify-content: center;
							}
							
							&.ag-cell-inline-editing {
								height: 100%;
								padding: 0px;
								box-shadow: none;
								border-radius: initial;
								background-color: inherit;

								.ag-cell-edit-wrapper {
									.ag-cell-editor {
										.ag-text-field-input-wrapper {
											input {
												border: none;
												background-color: transparent;
												color: var(--common-textcolor);

												&:focus {
													border-radius: initial;
												}
											}
										}
									}
								}
							}

							.ag-cell-wrapper.ag-row-group{
								align-items: center;

								.ag-icon{ //arrow icon
									font-size: 22px;
									line-height: 22px;
									color: #999;
								}
							}

							.ag-group-value{
								max-height: 100%;
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
		}

	
		.ag-popup {
			.ag-popup-editor {
				border: none;
				background-color: transparent;

				.ag-large-text {
					.ag-large-text-input {
						padding: 0px;

						.ag-wrapper {
							textarea.ag-input-field-input {
								border: none;
								box-shadow: none !important;
								color: #333333;
								color: var(--common-textcolor);
								background-color: #ffffff;
								background-color: var(--common-bgcolor);
							}
						}
					}
				}
			}
		}
	}
}

ag-grid-angular.forList {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-floating-top {
					.ag-pinned-left-floating-top {
						.ag-row {
							background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
							.ag-cell {
								padding: 0px;
								border: 0;
							}
						}
					}
					.ag-floating-top-viewport {
						.ag-floating-top-container {
							.ag-row {
								background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
								.ag-cell {
									padding: 0px;
									border: 0;
								}
							}
						}
					}
				}
				.ag-header {
					background-color: #fff;
                    border-bottom-style: solid;
                    border-bottom-width: var(--list-lineheight);
                    border-bottom-color: var(--list-linecolor);

					.ag-pinned-left-header, .ag-pinned-right-header {
						background-color: #ededed;
						background-color: var(--aggrid-headerbgcolor, #ededed);
						
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							
							.ag-header-cell {
								border: 0;
							}
						}
					}

					.ag-header-viewport {
						background-color: var(--aggrid-headerbgcolor);

						.ag-header-container {
							.ag-header-row {
								background-color: var(--aggrid-headerbgcolor);
								border-bottom-width: 1px;
								border-bottom-style: solid;
								border-bottom-color: var(--aggrid-linecolor);
								
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding-left: 15px;
									padding-right: 15px;

									.ag-floating-filter-body{
										input.ag-text-field-input{
											color: #333;
											color: var(--common-textcolor);
											background-color: var(--aggrid-filterinputbgcolor);
											border-color: var(--aggrid-filterinputbordercolor);

											&:disabled{
												color: #999;
												color: var(--common-textcolor3);
												background-color: var(--aggrid-filterinputbgcolor-disabled);
												border-color: var(--aggrid-filterinputbordercolor-disabled);
											}
										}
									}

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											width: var(--aggrid-filtericonsize);
											height: var(--aggrid-filtericonsize);
											cursor: pointer;

											.ag-icon-filter {
												color: var(--aggrid-filtericoncolor);
												font-size: var(--aggrid-filtericonsize);
											}
										}
									}
									&:last-child{
										.ag-header-cell-resize{
											display: none;
										}
									}

									.ag-header-cell-resize {
										width: 30px;
										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: var(--list-headerfontsize, 15px);
                                                color: var(--list-headerfontcolor, #666666);
												font-weight: normal;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							.ag-header-cell {
								padding-left: 15px;
								padding-right: 15px;
							}
						}
					}
				}

				.ag-body-viewport {
					background-color: var(--aggrid-bgcolor);
					padding-bottom: 50px;
					
					.ag-pinned-left-cols-container {
						.ag-row {
							background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
						}
					}

					.ag-center-cols-viewport {

						.ag-center-cols-container {
							// width: 100% !important;

							.ag-row {
								background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
                                height: var(--list-rowheight, 50px) !important;
								border-color: var(--list-linecolor);
                                border-bottom-style: solid;
                                border-bottom-width: var(--list-lineheight);
                                border-bottom-color: var(--list-linecolor);
								width: 100%;
								color: #333;
								color: var(--common-textcolor, #333);

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: transparent;

									&::after {
										content: "";
										position: absolute;
										background-color: var(--section-maincolor, transparent);
										width: 100%;
										height: 100%;
										z-index: -1;
										opacity: 0.1;
									}
								}

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33);
								}

								&.archived:not(.ag-row-selected), &.deleted:not(.ag-row-selected) {
									color: #999;
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding-left: 15px;
									padding-right: 15px;
									border: none;

									&:has(no-cell-renderer) {
										padding: 0px;
									}

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									&.iconBtn {
										padding: 0px;
										display: flex;
										justify-content: center;
									}

									&.ag-cell-inline-editing {
										height: 100%;
										padding: 0px;
										box-shadow: none;
										border-radius: initial;
										background-color: inherit;
		
										.ag-cell-edit-wrapper {
											.ag-cell-editor {
												.ag-text-field-input-wrapper {
													input {
														border: none;
														background-color: transparent;
														color: var(--common-textcolor);
		
														&:focus {
															border-radius: initial;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
			
		}

		.ag-popup{
			.ag-tabs.ag-menu{
				background-color: var(--ag-control-panel-background-color);
				border-color: var(--aggrid-bordercolor);
				color: var(--aggrid-headertextcolor);
				box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px 1px;

				.ag-tabs-header{
					border-bottom-color: var(--aggrid-bordercolor);
				}
			}
		}
	}
}

ag-grid-angular.calendar {

	&.empty{
		.ag-floating-top{
			display:none !important;
		}
	}

	

	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-floating-top {
					.ag-pinned-left-floating-top {
						.ag-row {
							background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
							.ag-cell {
								padding: 0px;
								border: 0;
							}
						}
					}
					.ag-floating-top-viewport {
						.ag-floating-top-container {
							.ag-row {
								background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
								.ag-cell {
									padding: 0px;
									border: 0;
								}
							}
						}
					}
				}
				.ag-header {
					background-color: #fff;
                    border-bottom-style: solid;
                    border-bottom-width: var(--list-lineheight);
                    border-bottom-color: var(--list-linecolor);

					.ag-pinned-left-header, .ag-pinned-right-header {
						background-color: #ededed;
						background-color: var(--aggrid-headerbgcolor, #ededed);
						
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							
							.ag-header-cell {
								border: 0;
							}
						}
					}

					

					.ag-header-viewport {
						background-color: var(--aggrid-headerbgcolor);

						.ag-header-container {
							.ag-header-row {
								background-color: var(--aggrid-headerbgcolor);
								border-bottom-width: 1px;
								border-bottom-style: solid;
								border-bottom-color: var(--aggrid-linecolor);
								
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding-left: 15px;
									padding-right: 15px;

									.ag-floating-filter-body{
										input.ag-text-field-input{
											color: #333;
											color: var(--common-textcolor);
											background-color: var(--aggrid-filterinputbgcolor);
											border-color: var(--aggrid-filterinputbordercolor);

											&:disabled{
												color: #999;
												color: var(--common-textcolor3);
												background-color: var(--aggrid-filterinputbgcolor-disabled);
												border-color: var(--aggrid-filterinputbordercolor-disabled);
											}
										}
									}

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											width: var(--aggrid-filtericonsize);
											height: var(--aggrid-filtericonsize);
											cursor: pointer;

											.ag-icon-filter {
												color: var(--aggrid-filtericoncolor);
												font-size: var(--aggrid-filtericonsize);
											}
										}
									}
									&:last-child{
										.ag-header-cell-resize{
											display: none;
										}
									}

									.ag-header-cell-resize {
										width: 30px;
										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: var(--list-headerfontsize, 15px);
                                                color: var(--list-headerfontcolor, #666666);
												font-weight: normal;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							.ag-header-cell {
								padding-left: 15px;
								padding-right: 15px;
							}
						}
					}

					.ag-pinned-left-header{
						.ag-header-row{
							border-bottom-width: 0px;
						}
					}
				}

				.ag-body-viewport {
					background-color: var(--aggrid-bgcolor);
					padding-bottom: 50px;
					
					.ag-pinned-left-cols-container {
						.ag-row {
							// background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
							border: 1px solid var(--list-linecolor);
							border-top: 0;
						}

						&.ag-row-hover{
							// background-color: transparent;

							&::after {
								content: "";
								display: inline-flex;
								position: absolute;
							}

							&::after {
								content: "";
								position: absolute;
								background-color: var(--section-maincolor);
								width: 100%;
								height: 100%;
								z-index: -1;
								opacity: 0.1;
							}
						}

					}

					.ag-center-cols-viewport {

						.ag-center-cols-container {
							// width: 100% !important;

							.ag-row {
								// background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
                                height: var(--list-rowheight, 50px) !important;
								border-color: var(--list-linecolor);
                                border-bottom-style: solid;
                                border-bottom-width: var(--list-lineheight);
                                border-bottom-color: var(--list-linecolor);
								width: 100%;
								color: #333;
								color: var(--common-textcolor, #333);

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: transparent;

									&::after {
										content: "";
										position: absolute;
										background-color: var(--section-maincolor);
										width: 100%;
										height: 100%;
										z-index: -1;
										opacity: 0.1;
									}
								}

								

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33);
								}

								&.archived:not(.ag-row-selected), &.deleted:not(.ag-row-selected) {
									color: #999;
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding-left: 15px;
									padding-right: 15px;
									border: none;

									&:has(no-cell-renderer) {
										padding: 0px;
									}

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									&.iconBtn {
										padding: 0px;
										display: flex;
										justify-content: center;
									}

									&.ag-cell-auto-height{
										&.remark{
												&.ag-cell-value:hover{
													border: 1px solid var(--section-maincolor-30) 
												}
										}
									}

									&.ag-cell-inline-editing {
										height: 100%;
										padding: 0px;
										box-shadow: none;
										border-radius: initial;
										background-color: inherit;

										.ag-cell-edit-wrapper {
											.ag-cell-editor {
												.ag-text-field-input-wrapper {
													input {
														border: none;
														background-color: transparent;
														color: var(--common-textcolor);
														cursor: text;

														&:focus {
															border-radius: initial;
															border: 1px solid var(--section-maincolor-10);
															background-color: var(--section-maincolor-20);
															
														}

														
													}

													
												}

												
											}

											
										}
									}
								}

								
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						// color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
			
		}

		.ag-popup{
			.ag-tabs.ag-menu{
				background-color: var(--ag-control-panel-background-color);
				border-color: var(--aggrid-bordercolor);
				color: var(--aggrid-headertextcolor);
				box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px 1px;

				.ag-tabs-header{
					border-bottom-color: var(--aggrid-bordercolor);
				}
			}
		}
	}
    .ag-header-cell.name {
		border-bottom:1px var(--aggrid-linecolor) solid !important;
	}

	.ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-body-viewport .ag-center-cols-viewport .ag-center-cols-container .ag-row.noHover .ag-cell.ag-cell-auto-height.remark.ag-cell-value:hover{
      border: 0 !important;
	}
}

ag-grid-angular.studentDiversityList {
	.ag-root-wrapper {
		border: 0;
		overflow: initial;

		.ag-root-wrapper-body {
			.ag-root {
				.ag-floating-top {
					.ag-pinned-left-floating-top {
						.ag-row {
							background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
							.ag-cell {
								padding: 0px;
								border: 0;
							}
						}
					}
					.ag-floating-top-viewport {
						.ag-floating-top-container {
							.ag-row {
								background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
								.ag-cell {
									padding: 0px;
									border: 0;
								}
							}
						}
					}
				}
				.ag-header {
					background-color: #fff;
                    border-bottom-style: solid;
                    border-bottom-width: var(--list-lineheight);
                    border-bottom-color: var(--list-linecolor);

					.ag-pinned-left-header, .ag-pinned-right-header {
						background-color: #ededed;
						background-color: var(--aggrid-headerbgcolor, #ededed);
						
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							
							.ag-header-cell {
								border: 0;
							}
						}
					}

					.ag-header-viewport {
						background-color: var(--aggrid-headerbgcolor);

						.ag-header-container {
							.ag-header-row {
								background-color: var(--aggrid-headerbgcolor);
								border-bottom-width: 1px;
								border-bottom-style: solid;
								border-bottom-color: var(--aggrid-linecolor);
								
								&:last-child {
									border-bottom-width: 0px;
								}

								.ag-header-cell {
									border: 0;
									padding-left: 15px;
									padding-right: 15px;

									.ag-floating-filter-body{
										input.ag-text-field-input{
											color: #333;
											color: var(--common-textcolor);
											background-color: var(--aggrid-filterinputbgcolor);
											border-color: var(--aggrid-filterinputbordercolor);

											&:disabled{
												color: #999;
												color: var(--common-textcolor3);
												background-color: var(--aggrid-filterinputbgcolor-disabled);
												border-color: var(--aggrid-filterinputbordercolor-disabled);
											}
										}
									}

									.ag-floating-filter-button {
										.ag-floating-filter-button-button {
											width: var(--aggrid-filtericonsize);
											height: var(--aggrid-filtericonsize);
											cursor: pointer;

											.ag-icon-filter {
												color: var(--aggrid-filtericoncolor);
												font-size: var(--aggrid-filtericonsize);
											}
										}
									}
									&:last-child{
										.ag-header-cell-resize{
											display: none;
										}
									}

									.ag-header-cell-resize {
										width: 30px;
										&::after {
											opacity: 1 !important;
											background-color: var(--aggrid-bordercolor);
										}
									}

									.ag-cell-label-container {
										.ag-header-cell-label {
											.ag-header-cell-text {
												font-size: var(--list-headerfontsize, 15px);
                                                color: var(--list-headerfontcolor, #666666);
												font-weight: normal;
											}
										}
									}
								}
							}
						}
					}

					.ag-pinned-left-header, .ag-pinned-right-header {
						.ag-header-row {
							background-color: var(--aggrid-headerbgcolor);
							border-bottom-width: 1px;
							border-bottom-style: solid;
							border-bottom-color: var(--aggrid-linecolor);
							.ag-header-cell {
								padding-left: 15px;
								padding-right: 15px;
							}
						}
					}
				}

				.ag-body-viewport {
					background-color: var(--aggrid-bgcolor);
					padding-bottom: 50px;
					
					.ag-pinned-left-cols-container {
						.ag-row {
							background-color: var(--aggrid-bgcolor);
							color: var(--aggrid-textcolor);
						}
					}

					.ag-center-cols-viewport {

						.ag-center-cols-container {
							// width: 100% !important;

							.ag-row {
								background-color: var(--aggrid-bgcolor);
								color: var(--aggrid-textcolor);
                                // height: var(--list-rowheight, 50px) !important;
								border-color: var(--list-linecolor);
                                border-bottom-style: solid;
                                border-bottom-width: var(--list-lineheight);
                                border-bottom-color: var(--list-linecolor);
								width: 100%;
								color: #333;
								color: var(--common-textcolor, #333);

								&::after {
									content: "";
									display: inline-flex;
									position: absolute;
								}

								&.ag-row-hover{
									background-color: transparent;

									&::after {
										content: "";
										position: absolute;
										background-color: var(--section-maincolor, transparent);
										width: 100%;
										height: 100%;
										z-index: -1;
										opacity: 0.1;
									}
								}

								&.ag-row-selected{
									background-color: var(--section-maincolor20pt, --section-maincolor, #684ccc33);
								}

								&.archived:not(.ag-row-selected), &.deleted:not(.ag-row-selected) {
									color: #999;
								}

								.ag-cell {
									display: inline-flex;
									flex: 0 0 auto;
									flex-direction: row;
									align-items: center;
									padding-left: 15px;
									padding-right: 15px;
									border: none;

									&:has(no-cell-renderer) {
										padding: 0px;
									}

									&.cell-button {
										padding: 0;
										justify-content: center;
										align-items: center;
									}

									&.iconBtn {
										padding: 0px;
										display: flex;
										justify-content: center;
									}

									&.ag-cell-inline-editing {
										height: 100%;
										padding: 0px;
										box-shadow: none;
										border-radius: initial;
										background-color: inherit;
		
										.ag-cell-edit-wrapper {
											.ag-cell-editor {
												.ag-text-field-input-wrapper {
													input {
														border: none;
														background-color: transparent;
														color: var(--common-textcolor);
		
														&:focus {
															border-radius: initial;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
			
		}

		.ag-popup{
			.ag-tabs.ag-menu{
				background-color: var(--ag-control-panel-background-color);
				border-color: var(--aggrid-bordercolor);
				color: var(--aggrid-headertextcolor);
				box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px 1px;

				.ag-tabs-header{
					border-bottom-color: var(--aggrid-bordercolor);
				}
			}
		}
	}
}

ag-grid-angular.scoreDataList {
	div.ag-root-wrapper.ag-layout-normal, div.ag-root-wrapper.ag-layout-auto-height {
		transition: border-color 0.25s, background-color 0.25s;
		background-color: var(--aggrid-bgcolor);
        border: none !important;
		border-color: var(--aggrid-linecolor);

		.ag-root-wrapper-body {
			.ag-root {
				.ag-header {
					transition: background-color 0.25s;
					background-color: var(--aggrid-bgcolor);
					border-color: var(--aggrid-linecolor);
                    font-size: 15px;
					.ag-pinned-left-header, .ag-pinned-right-header {
						border-color: var(--aggrid-linecolor);

						.ag-header-row {
							color: var(--aggrid-headertextcolor2);
						}
					}

					.ag-header-viewport {
						.ag-header-container {
                            background-color: var(--aggrid-headerbgcolor);
							.ag-header-row {
								color: var(--aggrid-headertextcolor2);

								.ag-header-cell {
									border-color: var(--aggrid-linecolor);
                                    padding: 10px ;
									.ag-header-cell-text{
										font-weight: normal !important;
									}

									.ag-header-cell-resize {
                                        width: 30px;
										&:after {
											background-color: var(--aggrid-bordercolor,#cccccc);
										}
									}

									.ag-floating-filter-body {
										.ag-floating-filter-input {
											.ag-labeled {
												.ag-input-wrapper {
													.ag-input-field-input {
														color: #333;
														color: var(--common-textcolor);
														background-color: #ffffff;
														background-color: var(--aggrid-filterinputbgcolor,
																#ffffff);
														border-color: #babfc7;
														border-color: var(--aggrid-filterinputbordercolor,
																#babfc7);

														&:disabled {
															// background-color: rgba(255,
															// 		255,
															// 		255,
															// 		0.5);
															color: #999;
															background-color: var(--common-textcolor3);
															background-color: var(--aggrid-filterinputbgcolor-disabled,
																rgba(255,255,255,0.5));
                                                            border-color: var(--aggrid-filterinputbordercolor-disabled,
                                                            rgba(204,204,204,0.4));
														}
													}
												}
											}
										}
									}

									.ag-floating-filter-button {
                                        margin-left: 5px;
                                        margin-right: 15px;
										.ag-floating-filter-button-button {
											.ag-icon {
												cursor: pointer;
												font-size: var(--aggrid-filtericonsize,
														22px);
												color: var(--aggrid-filtericoncolor,
														#999999);
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-body-viewport {
                    .archived_book{
                        color: #999999 !important;
                        
                    }
					.ag-row {
						transition: background-color 0.25s;
						background-color: var(--aggrid-bgcolor);
						color: var(--common-textcolor);
						border-color: var(--aggrid-linecolor);

                        &.ag-row-hover {
							background-color: color-mix(in srgb, var(--section-maincolor), transparent 90%);
                        }
                        &.ag-row-hover .ag-column-hover{
                            // border: solid 1px ;
                            // border-color: #1f859255 ;
                        }

						.ag-cell {
							border-color: #1f859255 ;
							border-width: 0px;
                            padding: 5px 10px;
                            
							&.ag-cell-inline-editing {
								height: 100%;
								padding: 0px;
								box-shadow: none;
								border-radius: initial;
								background-color: inherit;


								.ag-cell-edit-wrapper {
									.ag-cell-editor {
										.ag-text-field-input-wrapper {
											input {
												border: none;
												background-color: transparent;
												color: var(--common-textcolor);

												&:focus {
													border-radius: initial;
												}
											}
										}
									}
								}
							}

							.ag-cell-wrapper.ag-row-group{
								align-items: center;

								.ag-icon{ //arrow icon
									font-size: 22px;
									line-height: 22px;
									color: #999;
								}
							}

							.ag-group-value{
								max-height: 100%;
							}
						}
					}
				}
			}

			.ag-side-bar {
				color: var(--common-textcolor);
				border-left-color: var(--aggrid-bordercolor);

				.ag-side-buttons {
					color: var(--common-textcolor);

					.ag-side-button-button:hover {
						//						color: inherit !important;
						color: var(--section-maincolor) !important;
					}
				}

				.ag-tool-panel-wrapper {
					background-color: transparent;
					border-right-color: var(--aggrid-bordercolor);

					.ag-column-panel {
						.ag-column-select {
							border-bottom-color: var(--aggrid-bordercolor);
							border-top-color: var(--aggrid-bordercolor);

							.ag-column-select-header {
								border-bottom-color: var(--aggrid-bordercolor);
							}

							.ag-column-select-list {
								.ag-virtual-list-viewport {
									.ag-virtual-list-container {
										.ag-virtual-list-item {
											.ag-column-select-column {
												.ag-column-select-checkbox {
													.ag-checkbox-input-wrapper {
														&.ag-checked::after {
															color: var(--section-maincolor);
														}
													}
												}

												.ag-drag-handle {
													color: var(--common-textcolor);
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ag-side-button {
					&.ag-selected {
						.ag-side-button-button {
							border-left-color: var(--section-maincolor) !important;
						}
					}
				}
			}
		}

		.ag-popup {
			.ag-popup-editor {
				border: none;
				background-color: transparent;

				.ag-large-text {
					.ag-large-text-input {
						padding: 0px;

						.ag-wrapper {
							textarea.ag-input-field-input {
								border: none;
								box-shadow: none !important;
								color: #333333;
								color: var(--common-textcolor);
								background-color: #ffffff;
								background-color: var(--common-bgcolor);
							}
						}
					}
				}
			}
		}
	}
}

.ag-floating-filter-button-button {
	color: #473779 !important;
}

.ag-input-field-input:focus {
	box-shadow: 0 0 2px 0.1rem rgb(71 55 121 / 40%) !important;
}

.ag-tab.ag-tab-selected {
//	color: #473779 !important;
//	border-bottom-color: #473779 !important;
	color: var(--section-maincolor) !important;
	border-bottom-color: var(--section-maincolor) !important;
}

.ag-side-button {
	.ag-side-button-button:hover {
		color: #473779 !important;
	}

	&.ag-selected {
		.ag-side-button-button {
			border-left-color: #473779 !important;
		}
	}
}

.ag-selection-checkbox,
.ag-header-cell {
	.ag-checkbox {
		.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
			/*&:focus-within, &:active {
                box-shadow: 0 0 2px 0.1rem rgb(71 55 121 / 40%) !important;
            }*/
			width: 22px;
			height: 22px;
			border-radius: 50%;
			overflow: hidden;
			box-shadow: none;

			&::after {
				content: "\f12e";
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: rgba(0, 0, 0, 0.2);
				color: #fff;
				font-weight: bold;
				font-size: 14px;
			}

			&.ag-indeterminate::after {
				content: "\f130";
			}

			&.ag-checked::after {
				background-color: #8a1932;
			}
		}
	}
}

.ag-drag-handle {
	color: var(--common-textcolor) !important;
}

.ag-theme-alpine .ag-icon-grip{
	color: transparent;
	background-color: var(--aggrid-filtericoncolor);
	mask: url("/assets/img/aggrid/bars-solid.svg") no-repeat 50% 50%;
}

.ag-full-width-container{
	.ag-row-loading{
		border-color: var(--list-linecolor);
	
		.ag-loading-icon{
			color: #999;
		}
	}
}

.ag-group-contracted, .ag-group-expanded{
	.ag-icon-tree-closed, .ag-icon-tree-open{
		&::before{
			font-size: 22px;
			color: #999;
		}
	}
}