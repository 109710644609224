.page-panel.workspace-cloud-share-book,.page-panel.workspace-cloud-share-file,.page-panel.workspace-cloud-share-excel,
.page-panel.workspace-cloud-share-powerpoint,.page-panel.workspace-cloud-share-pdf,.page-panel.workspace-cloud-share-image,
.page-panel.workspace-cloud-share-audio,.page-panel.workspace-cloud-share-video,.page-panel.workspace-cloud-share-word,
.page-panel.workspace-cloud-share-collection,
.page-panel.workspace-cloud-share-mix,
.page-panel.workspace-cloud-share-book-trash,.page-panel.workspace-cloud-share-excel-trash,.page-panel.workspace-cloud-share-word-trash,
.page-panel.workspace-cloud-share-collection-trash,.page-panel.workspace-cloud-share-powerpoint-trash,.page-panel.workspace-cloud-share-pdf-trash,
.page-panel.workspace-cloud-share-video-trash,.page-panel.workspace-cloud-share-audio-trash,.page-panel.workspace-cloud-share-file-trash,
.page-panel.workspace-cloud-share-empty,.page-panel.workspace-cloud-share-excel-image,
.page-panel.workspace-cloud-share-mix-trash, .page-panel.workspace-cloud-share-assessment,.page-panel.workspace-cloud-share-pdf-paper{
//   left: calc((100vw - 150px) / 2);
//   margin-left: 0 !important;

  div.page-panel__num-label{
    // display: none !important;
  }

  &.bottom-60{
    bottom: -60px !important;
    transition: bottom 0.1s;
  }

  &.display-none{
    display: none !important;
  }
}

/*.page-panel.workspace-cloud-share-assessment{
  position: fixed !important;
  width: 780px !important;
  left:calc((100% - 780px)/2) !important;
  margin-left:unset !important;
  
  div.page-panel__num-label{
    display: none !important;
  }

  &.bottom-60{
    bottom: -60px !important;
    transition: bottom 0.1s;
  }

  &.display-none{
    display: none !important;
  }
}*/
.page-panel.workspace-cloud-share-book,.page-panel.workspace-cloud-share-file,.page-panel.workspace-cloud-share-excel,
.page-panel.workspace-cloud-share-powerpoint,.page-panel.workspace-cloud-share-pdf,.page-panel.workspace-cloud-share-image,
.page-panel.workspace-cloud-share-audio,.page-panel.workspace-cloud-share-video,.page-panel.workspace-cloud-share-word,
.page-panel.workspace-cloud-share-assessment{
  width: 880px !important;
  left: -135px !important;
//   left: calc((100vw - 150px - 780px) / 2) !important;
}
.page-panel.workspace-cloud-share-pdf-paper{
	width: 840px !important;
  	left: -115px !important;
}
.page-panel.workspace-cloud-share-collection{
  width: 840px !important;
  left: -115px !important;
//   left: calc((100vw - 150px - 720px) / 2) !important;
}

.page-panel.workspace-cloud-share-mix,.page-panel.workspace-cloud-share-book-trash{
  width: 460px !important;
  left: 70px !important;
//   left: calc((100vw - 150px - 300px) / 2) !important;
}

.page-panel.workspace-cloud-share-mix{
  width: 460px !important;
  left: 70px !important;
//   left: calc((100vw - 150px - 360px) / 2) !important;
}

.page-panel.workspace-cloud-share-book-trash,.page-panel.workspace-cloud-share-file-trash,.page-panel.workspace-cloud-share-word-trash,
.page-panel.workspace-cloud-share-excel-trash,.page-panel.workspace-cloud-share-video-trash,.page-panel.workspace-cloud-share-audio-trash,
.page-panel.workspace-cloud-share-assessment-trash,.page-panel.workspace-cloud-share-pdf-trash,.page-panel.workspace-cloud-share-image-trash,
.page-panel.workspace-cloud-share-pdf-paper-trash{
  width: 460px !important;
  left: 70px !important;
//   left: calc((100vw - 150px - 360px) / 2) !important;
}

.page-panel.workspace-cloud-share-collection-trash,.page-panel.workspace-cloud-share-mix-trash{
  width: 340px !important;
  left: 130px !important;
//   left: calc((100vw - 150px - 240px) / 2) !important;
}
