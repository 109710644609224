@import 'variables';

angular-cropper.forIconEditor {
    .cropper-wrapper {
        .cropper {
            .cropper-container {
                .cropper-crop-box, .cropper-view-box {
                    border-radius: 50%;
                    
                    .cropper-view-box {
                        box-shadow: 0 0 0 2px #fff;
                        outline: 0;
                    }

                    .cropper-point {
                        background-color: #fff;
                    }

                    .cropper-line {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    
}