@import 'variables';

.ng-select {
	width: 100%;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>div.ng-select-container {
	border-color: #ccc !important;
	box-shadow: none !important;
}

.ng-select div.ng-select-container {
	border: none;
	//border-radius: 5px;
	min-height: 30px;
}

.ng-select.ng-select-single div.ng-select-container {
	height: 100%;
	width: 100%;
	border-radius: 0px;
}

//#333333

.ng-select.redeem {
	height: 46px;
	width: 200px;
	background-color: #fff;
	scrollbar-base-color: #ccc;
	scrollbar-3dlight-color: #ccc;
	scrollbar-highlight-color: #ccc;
	scrollbar-track-color: #3e3e42;
	scrollbar-arrow-color: #ccc;
	scrollbar-shadow-color: #ccc;
	box-shadow: 0;

	-ms-overflow-style: -ms-autohiding-scrollbar;
	display: inline-block !important;
	vertical-align: top;
	cursor: pointer;
	outline: 0;

	&:not(.ng-select-opened) {
		.ng-has-value {
			.ng-placeholder {
				display: none;
			}
		}
	}

	&.ng-select-disabled {
		.ng-select-container {
			background-color: #f3e7ee;

			.ng-value-label {
				color: #999;
			}
		}
	}

	&.ng-dirty.ng-invalid {
		.ng-placeholder {
			color: #f00;
		}
	}

	&.ng-select-opened {

		.ng-value-label {
			// display: none;
		}

		.ng-value-container {
			overflow: visible !important;

			.ng-value {
				top: -5px;
				line-height: 30px;
				position: relative;
			}
		}

		.ng-select-container {
			width: calc(100% - 20px);
			height: 46px;
			left: 4px;
			background-color: #fff;
			border-color: #b3b3b3 #ccc #d9d9d9;
			border-radius: 4px;
			min-height: 30px;
			cursor: pointer !important;
			align-items: center;
			color: #333;
			outline: 0;
			overflow: hidden;
			position: relative;
			border-top: 1px solid #eee;

			&:hover {
				box-shadow: none;
			}

			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				width: calc(100% - 16px);
				height: 1px;
				left: 8px;
				background-color: #aaa;
			}

			&.ng-has-value {
				.ng-placeholder {
					display: none;
				}
			}

			.ng-arrow {
				top: -6px;
				border-color: transparent transparent #999;
				border-width: 0 5px 5px;

				&:hover {
					border-color: transparent transparent #666;
				}
			}

			.ng-value-container {
				align-items: center;
				padding-left: 10px;
			}
		}

		.ng-dropdown-panel {
			width: calc(100% - 20px);
			left: 4px;
			border: 0;
			outline: 0;
			background-color: #fff;
			text-align: left;

			&.ng-select-top {
				bottom: 45px;
				border-radius: 10px 10px 0 0;
				padding-top: 7px;
			}

			&.ng-select-bottom {
				top: 45px;
				border-radius: 0 0 10px 10px;
				padding-bottom: 7px;
			}

			.ng-dropdown-panel-items {
				.ng-option {
					color: #621B49;
					background-color: #fff;
					// padding: 0 40px;
					box-sizing: border-box;
					cursor: pointer;
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&.ng-option-marked {
						background-color: #f3e7ee;
					}

					&.ng-option-selected {
						font-weight: bold;
					}
				}
			}
		}

		&.ng-select-top {

			.ng-select-container {
				bottom: -2px;
				box-shadow: 0 0 -5 0 rgba(0, 0, 0, 0.2);
				border-radius: 0 0 10px 10px;

				&:after {
					border-bottom: 1px solid #eee;
					top: 8px;
				}
			}

			.ng-dropdown-panel {
				box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
			}
		}

		&.ng-select-bottom {

			.ng-select-container {
				top: 5px;
				box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
				border-radius: 10px 10px 0 0;


				&:after {
					border-top: 1px solid #eee;
					bottom: 8px;
				}
			}

			.ng-dropdown-panel {
				box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
			}
		}

	}

	.ng-select-container {
		height: 46px;
		border-radius: 0;
		border: 0;
		outline: 0;
		box-shadow: none;
		color: #621B49;
		display: flex;
		outline: 0;
		overflow: hidden;
		position: relative;
		width: 150px;
		align-items: center;

		.ng-value-container {
			padding-left: 10px;
			display: flex;
			flex: 1;
			align-items: center;
			overflow: visible;
		}
	}

	&.last:not(.ng-select-opened),
	&.last:not(.ng-select-opened) .ng-select-container {
		border-radius: 0 0 10px 0;
	}

	&.first:not(.ng-select-opened),
	&.first:not(.ng-select-opened) .ng-select-container {
		border-radius: 10px 0 0 0;
	}

	input {
		outline: none;
		font-weight: normal;
	}

	.ng-arrow-wrapper {
		width: 25px;
		padding-right: 5px;
		cursor: pointer;
		position: relative;
		text-align: center;
		user-select: none;

		.ng-arrow {
			border-color: #666 transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
			pointer-events: none;
			display: inline-block;
			height: 0;
			width: 0;
			position: relative;
		}
	}

	.ng-value-label {
		color: #306a62;
		font-weight: normal;
		font-size: 15px;
		line-height: 1.5;
		@extend %rainBowFont;
	}

	.ng-placeholder {
		color: #306a62;
	}

}

.ng-select.redeem-filter {
	outline: 0;
	height: 30px;
	min-width: 100px;
	background-color: #fff;
	border-radius: 5px;

	&.ng-select-opened .ng-select-container {
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	.ng-select-container {
		border-radius: 4px !important;
		color: #333;
		border: 1px solid #ccc;
		overflow: hidden;
	}

	.ng-optgroup {
		color: #666;
		font-size: 15px;
		padding-left: 10px;
		line-height: 24px;
		border-top: 1px solid #ccc;

		&:first-child {
			display: none;
		}

		.my-tag {
			color: #621b49;
			font-weight: bold;
		}

		.all-tag {
			color: #666;
			font-weight: bold;
		}

	}

	.ng-value {
		line-height: 30px;
		font-size: 14px;
	}

	.ng-option {
		line-height: 40px;
		padding: 0 10px !important;
		padding-left: 10px !important;
		font-size: 14px;

		span {
			@extend %text-ellipsis;
		}

		.my-tag {
			color: #621b49;
		}

		.all-tag {
			color: #666;
		}

		.all {
			font-weight: bold;
		}
	}


	.ng-option-marked {
		background-color: #faf5f9 !important;
	}

	.ng-option-selected {
		background-color: #f5e6f3 !important;
	}

	.ng-dropdown-panel-items {
		max-height: 40vh;
	}
}

.ng-select.redeem-status {

	&.ng-select-opened .ng-select-container {
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	>.ng-select-container {
		border: 0;
		border-radius: 5px !important;

		.ng-value-container {
			justify-content: center;
			width: 100px;
			flex: none;
			padding-left: 0 !important;

			.ng-value {
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
			}
		}

		.ng-arrow-wrapper {
			position: absolute;
			right: 0;
			top: 2px;
			height: 100%;
			align-items: center;
			display: inline-flex;
			padding: 0;
			justify-content: center;
		}
	}

	.ng-option {
		position: relative;
		line-height: 24px;
		font-size: 15px;
		padding-left: 33px !important;

		.icon {
			position: absolute;
			display: inline-flex;
			width: 14px;
			height: 14px;
			top: 12px;
			left: 9px;
			border-radius: 50%;

			&.pending {
				background-color: #FEDA77;
			}

			&.redeemed {
				background-color: #B5E97E;
			}

			&.cancelled {
				background-color: #FFADAD;
			}

		}

		&.ng-option-selected {
			background-color: #D1F7ED;
			color: #245F4F !important;
		}

		&.ng-option-marked {
			background-color: #EAFAF6;
		}
	}

	&[status="pending"] .ng-select-container {
		background-color: #FEDA77;
	}

	&[status="redeemed"] .ng-select-container {
		background-color: #B5E97E;
	}

	&[status="cancelled"] .ng-select-container {
		background-color: #FFADAD;
	}

	.ng-value-container {
		line-height: 1.5;

		.ng-value {
			padding-right: 8px;
			font-size: 15px;
		}
	}
}

.ng-select.workspace-add-button {
	left: 210px;
	bottom: 10px;
	position: absolute;
	min-width: auto;
	background-color: transparent;

	.ng-select-container {
		background-color: transparent;
		width: 56px !important;
		height: 56px !important;
		background-image: url('/assets/img/btn_add_red.svg');
		background-size: cover;
		cursor: pointer;
		text-align: center;
		border: 0;

		&:hover {
			box-shadow: none;
		}
	}

	.ng-value-container {
		display: none !important;
	}

	.ng-dropdown-panel.ng-select-top {
		bottom: 57px;
		left: -42px;
		border: 0;
		width: auto;
		border-radius: 10px;

		&::after {
			content: "";
			position: absolute;
			left: 53px;
			bottom: -34px;
			border: 17px solid transparent;
			border-top: 17px solid #fff;
			pointer-events: none;
		}
	}

	.ng-dropdown-panel-items {
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0 15px 50px 0 rgba(0, 0, 0, .3);
	}

	.ng-option {
		text-align: center;
		line-height: 46px;
		width: 140px;
		padding: 0;
		border-top: 1px solid #ccc;
		background-color: #fff !important;
		color: #000 !important;

		&:first-child {
			border-top: 0;
		}

		span {
			@extend %text-ellipsis;
		}
	}

	.ng-arrow-wrapper {
		display: none;
	}
}

ng-select.tag-year {
	background-color: transparent;
	width: 230px;

	&.ng-select-opened {
		.ng-arrow {
			border-color: transparent transparent #fff;
		}
	}

	.ng-select-container {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.3) !important;
		border-color: transparent !important;

		.ng-arrow {
			border-color: #fff transparent transparent;
		}
	}

	.ng-dropdown-panel {
		background-color: rgba(0, 0, 0, 0.3);
		border: 0 !important;

		.ng-option {
			background-color: #333 !important;
			color: #fff !important;
		}
	}
}

ng-select.workspace-year {
	border-radius: 10px;

	&.ng-select-opened {
		.ng-select-container {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.ng-select-container {
		border-radius: 5px !important;
	}


}



ng-select.ng-select.workspace-publish {
	width: 250px;
	background-color: #fff;
	scrollbar-base-color: #ccc;
	scrollbar-3dlight-color: #ccc;
	scrollbar-highlight-color: #ccc;
	scrollbar-track-color: #3e3e42;
	scrollbar-arrow-color: #ccc;
	scrollbar-shadow-color: #ccc;
	scrollbar-dark-shadow-color: #ccc;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	&.ng-select-disabled {
		.ng-select-container {
			background-color: #f5f5f5;

			.ng-value-label {
				color: #999;
			}
		}
	}

	&.ng-dirty.ng-invalid.ng-confirmed {
		.ng-placeholder {
			color: #f00;
		}
	}

	&:not(.ng-select-opened) {
		.ng-has-value {
			.ng-placeholder {
				display: none;
			}
		}
	}

	&.ng-select-opened {
		.ng-value-label {
			display: none;
		}

		.ng-select-container {
			width: calc(100% - 8px);
			left: 4px;
			background-color: #fff;

			&:after {
				content: "";
				display: inline-block;
				position: absolute;
				width: calc(100% - 16px);
				height: 1px;
				left: 8px;
				background-color: #aaa;
			}

			.ng-value {
				display: none;
			}

			.ng-placeholder {
				display: inline-flex !important;
				top: 12px !important;
				color: #A63232 !important;
			}
		}

		.ng-dropdown-panel {
			width: calc(100% - 8px);
			left: 4px;
		}

		&.ng-select-bottom {
			.ng-select-container {
				top: 5px;
				box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
				border-radius: 10px 10px 0 0;

				&:after {
					border-top: 1px solid #eee;
					bottom: 2px;
				}
			}

			.ng-dropdown-panel {
				border: 0;
				box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
			}
		}

	}

	.ng-select-container {
		height: 46px;
		border-radius: 0;
		box-shadow: none;
		cursor: pointer;

		.ng-value-container {
			padding-left: 10px;
			align-items: center;

			.ng-value-icon {
				display: none;
			}

			.ng-value:not(:nth-child(2)) {
				margin-left: 10px;

				&:before {
					content: ",";
					display: inline;
					position: relative;
					left: -8px;
				}
			}

			.ng-value {
				background-color: transparent !important;
				color: #A63232;
			}

			.ng-placeholder {
				top: 15px;
			}
		}
	}

	&.last:not(.ng-select-opened),
	&.last:not(.ng-select-opened) .ng-select-container {
		border-radius: 0 0 10px 0;
	}

	&.first:not(.ng-select-opened),
	&.first:not(.ng-select-opened) .ng-select-container {
		border-radius: 10px 0 0 0;
	}

	input {
		outline: none;
		font-weight: normal;
	}

	.ng-value:nth-last-of-type(2):after {
		content: "...";
		border-bottom: 1px solid;
	}

	.ng-value-label {
		color: #A63232;
		font-weight: normal;
		font-size: 15px;
		border-bottom: 1px solid;
		@extend %rainBowFont;
	}

	.ng-placeholder {
		color: #A63232;
	}

	.ng-dropdown-panel {
		background-color: #fff;

		&.ng-select-bottom {
			top: 45px;
			border-radius: 0 0 10px 10px;
			padding-bottom: 7px;
		}

		.ng-dropdown-panel-items {
			max-height: 1240px;

			.ng-option {
				color: #A63232 !important;
				background-color: #fff;
				line-height: 46px;
				padding: 0 0 0 50px;
				background-image: url('/assets/img/sel40_gray1.svg');
				background-position: 10px center;
				background-repeat: no-repeat;
				background-size: 22px;

				&.ng-option-selected {
					background-image: url('/assets/img/sel40_red.svg');
				}

				&.ng-option-marked {
					background-color: #fff9f3;
				}

			}

		}
	}



}

ng-select.ng-select.workspace-r2 {
	height: 46px;
	width: 250px;
	background-color: #fff;
	scrollbar-base-color: #ccc;
	scrollbar-3dlight-color: #ccc;
	scrollbar-highlight-color: #ccc;
	scrollbar-track-color: #3e3e42;
	scrollbar-arrow-color: #ccc;
	scrollbar-shadow-color: #ccc;
	box-shadow: 0;

	-ms-overflow-style: -ms-autohiding-scrollbar;
	display: inline-block !important;
	vertical-align: top;
	cursor: pointer;
	outline: 0;

	&:not(.ng-select-opened) {
		.ng-value {
			background-color: #F0F0F0;
			background-color: var(--pulldown-bgcolor, #f0f0f0);
			color: #000;
			max-width: calc(100% - 50px);
			margin-left: 10px;
			border-radius: 5px;
			line-height: 30px;
			position: relative;
			padding-left: 10px;
			padding-right: 50px;
			border-bottom: 1px solid #D0D0D0;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				border-style: solid;
				display: inline-flex;
				height: 0;
				width: 0;
				right: 10px;
				top: 12px;
				border-width: 5px 5px 2.5px;
				border-color: #666 transparent transparent;
			}
		}

		.ng-has-value {
			.ng-placeholder {
				display: none;
			}
		}

		.ng-arrow-wrapper {
			display: none !important;
		}
	}

	&.ng-select-disabled {
		.ng-select-container {
			background-color: #f3e7ee;

			.ng-value-label {
				color: #999;
			}
		}
	}

	&.ng-dirty.ng-invalid {
		.ng-placeholder {
			color: #f00;
		}
	}

	&.ng-select-opened {

		.ng-value-label {
			// display: none;
		}

		.ng-value-container {
			overflow: visible !important;

			.ng-value {
				top: -5px;
				line-height: 30px;
				position: relative;
			}
		}

		.ng-select-container {
			width: calc(100% - 20px);
			height: 46px;
			left: 4px;
			background-color: #fff;
			border-color: #b3b3b3 #ccc #d9d9d9;
			border-radius: 4px;
			min-height: 30px;
			cursor: pointer !important;
			align-items: center;
			color: #333;
			outline: 0;
			overflow: hidden;
			position: relative;
			border-top: 1px solid #eee;

			&:hover {
				box-shadow: none;
			}

			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				width: calc(100% - 16px);
				height: 1px;
				left: 8px;
				background-color: #aaa;
			}

			&.ng-has-value {
				.ng-placeholder {
					display: none;
				}
			}

			.ng-arrow {
				top: -6px;
				border-color: transparent transparent #999;
				border-width: 0 5px 5px;

				&:hover {
					border-color: transparent transparent #666;
				}
			}

			.ng-value-container {
				align-items: center;
				padding-left: 10px;
			}
		}

		.ng-dropdown-panel {
			width: calc(100% - 20px);
			left: 4px;
			border: 0;
			outline: 0;
			background-color: #fff;
			text-align: left;

			&.ng-select-top {
				bottom: 45px;
				border-radius: 10px 10px 0 0;
				padding-top: 7px;
			}

			&.ng-select-bottom {
				top: 45px;
				border-radius: 0 0 10px 10px;
				padding-bottom: 7px;
			}

			.ng-dropdown-panel-items {
				.ng-option {
					color: #621B49;
					background-color: #fff;
					// padding: 0 40px;
					box-sizing: border-box;
					cursor: pointer;
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&.ng-option-marked {
						background-color: #f3e7ee;
					}

					&.ng-option-selected {
						font-weight: bold;
					}
				}
			}
		}

		&.ng-select-top {

			.ng-select-container {
				bottom: -2px;
				box-shadow: 0 0 -5 0 rgba(0, 0, 0, 0.2);
				border-radius: 0 0 10px 10px;

				&:after {
					border-bottom: 1px solid #eee;
					top: 8px;
				}
			}

			.ng-dropdown-panel {
				box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
			}
		}

		&.ng-select-bottom {

			.ng-select-container {
				top: 5px;
				box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
				border-radius: 10px 10px 0 0;

				&:after {
					border-top: 1px solid #eee;
					bottom: 8px;
				}
			}

			.ng-dropdown-panel {
				box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
			}
		}

	}

	.ng-select-container {
		height: 46px;
		border-radius: 0;
		border: 0;
		outline: 0;
		box-shadow: none;
		color: #621B49;
		display: flex;
		outline: 0;
		overflow: hidden;
		position: relative;
		width: 250px;
		align-items: center;

		.ng-value-container {
			padding-left: 10px;
			display: flex;
			flex: 1;
			align-items: center;
			overflow: visible;
		}
	}

	&.last:not(.ng-select-opened),
	&.last:not(.ng-select-opened) .ng-select-container {
		border-radius: 0 0 10px 0;
	}

	&.first:not(.ng-select-opened),
	&.first:not(.ng-select-opened) .ng-select-container {
		border-radius: 10px 0 0 0;
	}

	input {
		outline: none;
		font-weight: normal;
	}

	.ng-arrow-wrapper {
		width: 25px;
		padding-right: 5px;
		cursor: pointer;
		position: relative;
		text-align: center;
		user-select: none;

		.ng-arrow {
			border-color: #666 transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
			pointer-events: none;
			display: inline-block;
			height: 0;
			width: 0;
			position: relative;
		}
	}

	.ng-value-label {
		color: #306a62;
		font-weight: normal;
		font-size: 15px;
		line-height: 1.5;
		@extend %rainBowFont;
	}

	.ng-placeholder {
		color: #306a62;
	}

}

ng-select.ng-select.workspace-tag-year {
	background-color: transparent;
	width: 230px;
	border-radius: 10px;

	.ng-value {
		font-size: 13px;
		line-height: 15px;
	}

	&.ng-select-opened {
        .ng-select-container{
            .ng-arrow {
                border-color: transparent transparent #fff;
            }
        }
	}

	&.ng-select-top{
		.ng-dropdown-panel {
			&::after{
				border-bottom: 10px solid transparent;
				border-top: 10px solid #333;
				top: 199px;
			}

			.ng-dropdown-header{
				margin-top: 0;
			}
		}
	}

	.ng-select-container {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.3) !important;
		border-color: transparent !important;
		border-radius: 10px 10px 10px 10px !important;

		.ng-arrow {
			border-color: #fff transparent transparent;
		}
	}

	.ng-dropdown-panel {
		background-color: transparent;
		border: 0 !important;
		border-radius: 10px;
		bottom: 40px;
		box-shadow: 0 10px 30px 0 rgb(0 0 0 / 50%);

		&::after {
			position: absolute;
			content: "";
			border: 10px solid transparent;
			border-bottom: 10px solid #333;
			top: -9px;
			left: 100px;
		}

		>.ng-dropdown-header {
			border-bottom: 0;
			font-size: 15px;
			display: flex;
			height: 40px;
			align-items: center;
			padding-left: 10px;
			background-color: #333;
			margin-top: 10px;
			border-radius: 10px 10px 0 0;
		}

		>.ng-dropdown-panel-items {
			max-height: 160px;
            background-color: #333;
            border-radius: 0 0 10px 10px;
		}

		.ng-option {
			background-color: #333 !important;
			height: 40px;
			line-height: 40px;
			font-size: 15px;
            display: flex;
            align-items: center;
			color: #ccc !important;

            fa-icon{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 22px;
                color: rgba(255, 255, 255, 0.4);
                margin-right: 8px;

                &.checked {
                    --fa-primary-color: #fff;
                    --fa-secondary-color: #e4015a;
                    --fa-primary-opacity: 1;
                    --fa-secondary-opacity: 1;
                }
            }


			&:last-child {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}

ng-select.ng-select.redeem-tag-year {
	background-color: transparent;
	width: 230px;
	border-radius: 10px;

	.ng-value {
		font-size: 13px;
		line-height: 15px;
	}

	&.ng-select-opened {
		.ng-arrow {
			border-color: transparent transparent #fff;
		}
	}

	.ng-select-container {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.3) !important;
		border-color: transparent !important;
		border-radius: 10px 10px 10px 10px !important;

		.ng-arrow {
			border-color: #fff transparent transparent;
		}
	}

	.ng-dropdown-panel {
		background-color: #333;
		border: 0 !important;
		border-radius: 10px;
		bottom: 40px;
		box-shadow: 0 10px 30px 0 rgb(0 0 0 / 50%);

		&::after {
			position: absolute;
			content: "";
			border: 10px solid transparent;
			border-top: 10px solid #333;
			bottom: -18px;
			left: 100px;
		}

		>.ng-dropdown-header {
			border-bottom: 0;
			font-size: 15px;
			display: flex;
			height: 40px;
			align-items: center;
			padding-left: 10px;
		}

		>.ng-dropdown-panel-items {
			max-height: 160px;
		}

		.ng-option {
			background-color: #333 !important;
			padding: 0 0 0 40px;
			height: 40px;
			line-height: 40px;
			font-size: 15px;
			color: #ccc !important;
			background-image: url('/assets/img/sel40_gray1.svg');
			background-repeat: no-repeat;
			background-position: 7px center;
			background-size: 25px;

			&.ng-option-selected {
				background-image: url('/assets/img/sel40_red.svg');
			}

			&:last-child {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}

ng-select.ng-select.custom2 {
	scrollbar-base-color: #ccc;
	scrollbar-3dlight-color: #ccc;
	scrollbar-highlight-color: #ccc;
	scrollbar-track-color: #3e3e42;
	scrollbar-arrow-color: #ccc;
	scrollbar-shadow-color: #ccc;

	-ms-overflow-style: -ms-autohiding-scrollbar;
	display: inline-block !important;
	vertical-align: top;
	cursor: pointer;

	&:not(.ng-select-opened) {
		.ng-has-value {
			.ng-placeholder {
				display: none;
			}
		}
	}

	&.ng-select-disabled {
		.ng-select-container {
			.ng-value-label {
				color: #999;
			}
		}
	}

	&.ng-dirty.ng-invalid {
		.ng-placeholder {
			color: #f00;
		}
	}

	&.ng-select-opened {

		&::before {
			content: "";
			width: 100%;
			height: calc(100% + 20px);

			/* border: solid; */
			position: absolute;
			border-radius: 5px;
			/* box-shadow: 0px 0px 20px 3px #0000004d; */
			box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.3);
		}

		&.ng-select-top::before {
			bottom: 0;
		}

		.ng-value-container {
			overflow: visible !important;

			.ng-value {
				top: 0px;
				line-height: 30px;
				position: relative;
			}
		}

		.ng-select-container {
			background-color: #f0f0f0;
			background-color: var(--pulldown-bgcolor, #f0f0f0);
			border-color: #b3b3b3 #ccc #d9d9d9;
			cursor: pointer !important;
			align-items: center;
			color: #333;
			outline: 0;
			overflow: hidden;
			position: relative;
			text-align: center;

			&:hover {
				box-shadow: none;
			}

			&::after {
				content: "";
				display: inline-block;
				position: absolute;

				background-color: #aaa;
			}

			&.ng-has-value {
				.ng-placeholder {
					display: none;
				}
			}

			.ng-arrow {
				&:hover {
					border-color: transparent transparent #666;
				}
			}

			.ng-value-container {
				align-items: center;
			}
		}

		&.ng-select-top {
			.ng-select-container {
				&:after {
					border-bottom: 1px solid #eee;
				}
			}
		}

		&.ng-select-bottom {
			.ng-select-container {
				&:after {
					border-top: 1px solid #eee;
				}
			}
		}
	}

	&.ng-select-opened.ng-select-top .ng-select-container {
		border-radius: 0px 0px 5px 5px;
		/* box-shadow: 0px 2px 2px 20px red; */
	}

	&.ng-select-opened.ng-select-bottom .ng-select-container {
		border-radius: 5px 5px 0px 0px;
		/* box-shadow: 0px 2px 2px 20px red; */
	}

	&.ng-select-opened .ng-select-container {
		box-shadow: none;

		.ng-arrow {
			border-color: transparent transparent #666 transparent;
		}
	}

	.ng-select-container {
		font-weight: normal;
		align-items: center;
		border-radius: 5px;
		background-color: #f0f0f0;
		background-color: var(--pulldown-bgcolor, #f0f0f0);
		box-shadow: 0 1px 0px 0px #d0d0d0;

		.ng-value-container {
			.ng-value {
				line-height: 30px;
			}
		}
	}

	.ng-arrow-wrapper {
		cursor: pointer;

		.ng-arrow {
			border-color: #666 transparent transparent;
		}
	}

	.ng-value-label {
		color: #333333;
	}

	.ng-placeholder {
		color: #333333;
	}

	.ng-dropdown-panel {
		border: 0 !important;

		&::before {
			content: "";
			width: 100%;
			height: calc(100%);
			position: absolute;
			border-radius: 5px;
			box-shadow: 0px 10px 20px 3px rgb(0 0 0 / 30%);
		}
	}
}

.ng-dropdown-panel.custom2 {
	background-color: #ececec;
	border: 0 !important;

	.scroll-host::-webkit-scrollbar {
		width: 6px;
		background-color: #ff000000;
	}

	.scroll-host::-webkit-scrollbar-thumb {
		background-color: #d8d8d8;
		border-radius: 3px;
	}

	&.ng-select-top {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: solid 1px #d8d8d8;
		border-radius: 5px 5px 0px 0px;

		.ng-dropdown-panel-items {
			border-radius: 5px 5px 0px 0px;
		}
	}

	&.ng-select-bottom {
		border-top: solid 1px #d8d8d8;
		border-left: none;
		border-right: none;
		border-bottom: none;
		border-radius: 0px 0px 5px 5px;

		.ng-dropdown-panel-items {
			border-radius: 0px 0px 5px 5px;
		}
	}

	.ng-dropdown-panel-items {
		.ng-option {
			color: #333333;
			background-color: #f1eff0;
			background-color: var(--pulldown-bgcolor, #f1eff0);

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.ng-option-marked {
				background-color: #e9e4ec;
			}

			&.ng-option-selected {
				font-weight: bold;
			}
		}

		.ng-option.ng-option-selected,
		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
			color: #333333;
			background-color: #d9cde1;
		}
	}

	&::before {
		content: "";
		width: 100%;
		height: calc(100%);
		position: absolute;
		border-radius: 5px;
		box-shadow: 0px 10px 20px 3px rgb(0 0 0 / 30%);
	}
}

.ng-select.custom2-redeem {
	.ng-value {
		color: #894856 !important;
	}

	.ng-option {
		background-color: #f1eff0;

		&.ng-option-marked {
			background-color: #E2E2E2 !important;
		}

		&.ng-option-selected {
			background-color: #F3E7E9 !important;
		}
	}

	.ng-dropdown-panel-items {
		max-height: 150px;
	}
}

.ng-select.custom2-redeemNew {

	.ng-select-container {
		background-color: var(--pulldown-bgcolor, #f0f0f0);
		border-color: #b3b3b3 #ccc #d9d9d9;
		cursor: pointer !important;
		align-items: center;
		color: #333;
		box-shadow: 0 1px 0px 0px var(--pulldown-bottomcolor)!important;
	}

	.ng-value {
		color: var(--pulldown-labelcolor, #894856) !important;
	}

	.ng-option {
		color: var(--pulldown-textcolor);
		background-color: var(--pulldown-bgcolor, #f0f0f0);

		&.ng-option-marked {
			color: var(--pulldown-textcolor);
			background-color: var(--pulldown-bgcolor, #f0f0f0);
		}

		&.ng-option-selected, &.ng-option-selected.ng-option-marked {
			background-color: var(--section-maincolor);
			color: #fff;
		}
	}

	.ng-dropdown-panel-items {
		max-height: 150px;
	}
}

.ng-select.custom2-workspace {
	.ng-option {
		background-color: #f1eff0;

		&.ng-option-marked {
			background-color: #f5ecf0 !important;
		}

		&.ng-option-selected {
			background-color: #efdfe6 !important;
		}
	}
}

.ng-select.add-button {
	right: 10px;
	bottom: 10px;
	position: absolute;
	min-width: auto;
	width: 56px;
	height: 56px;
	background-color: transparent;

	&.theme-dark {
		--common-textcolor: #fff;
	}

	.ng-select-container {
		background-color: transparent;
		width: 56px;
		height: 56px;
		background-image: url('/assets/img/btn_add_684ccc.svg');
		background-size: cover;
		cursor: pointer;
		text-align: center;
		border: 0;

		&:hover {
			box-shadow: none;
		}
	}

	.ng-value-container {
		display: none !important;
	}


	.ng-dropdown-panel {
		background-color: #fff;
		background-color: var(--wpopup-bgcolor, #fff);

		&.ng-select-top {
			bottom: 57px;
			left: -42px;
			border: 0;
			width: auto;
			border-radius: 10px;

			&::after {
				content: "";
				position: absolute;
				left: 53px;
				bottom: -34px;
				border: 17px solid transparent;
				border-top: 17px solid #fff;
				border-top: 17px solid var(--wpopup-bgcolor, #fff);
				pointer-events: none;
			}
		}
	}

	.ng-dropdown-panel-items {
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0 15px 50px 0 rgba(0, 0, 0, .3);
		max-height: none;
	}

	.ng-option {
		text-align: center;
		line-height: 46px;
		width: 140px;
		padding: 0;
		border-top: 1px solid #ccc;
		border-top: 1px solid var(--list-linecolor, #ccc);
		background-color: #fff !important;
		background-color: var(--wpopup-bgcolor, #fff) !important;
		color: #333 !important;
		color: var(--common-textcolor, #333) !important;

		&:first-child {
			border-top: 0;
		}

		span {
			@extend %text-ellipsis;
		}
	}

	.ng-arrow-wrapper {
		display: none;
	}
}

ng-select.ng-select.readingToolModule {
	.ng-select-container {
		background-color: white;
	}

	&.ng-select-opened {
		.ng-select-container {
			background-color: white;
		}
	}
}

ng-dropdown-panel.ng-dropdown-panel.readingToolModule {
	.ng-dropdown-panel-items {
		.ng-option {
			color: #333333;
			background-color: white;
		}
	}
}

ng-select.ng-select.pdfConverterModule {
	font-size: 15px;

	.ng-select-container {
		background-color: white;
	}

	&.ng-select-opened {
		.ng-select-container {
			background-color: white;
		}
	}
}

ng-dropdown-panel.ng-dropdown-panel.pdfConverterModule {
	font-size: 15px;

	.ng-dropdown-panel-items {
		.ng-option {
			color: #333333;
			background-color: white;

			&.ng-option-selected {
				background-color: rgb(152 94 40 / 20%);
			}

			&.ng-option-marked {
				background-color: rgb(152 94 40 / 10%);
			}
		}
	}
}

ng-select.ng-select.userAndGroupModule {
	font-size: 15px;

	.ng-select-container {
		.ng-value-container {
			.ng-value {
				.ng-value-label {
					color: var(--section-maincolor);
				}
			}

			.ng-arrow {
				border-bottom-color: var(--section-maincolor);
			}
		}
	}

	&.ng-select-opened {
		.ng-select-container {}
	}
}

ng-dropdown-panel.ng-dropdown-panel.userAndGroupModule {
	--section-maincolor: #8449B4;
	font-size: 15px;

	.ng-dropdown-panel-items {
		div {
			.ng-option {
				color: var(--section-maincolor);

				&.ng-option-selected {
					color: var(--section-maincolor);
				}
			}
		}
	}
}

ng-select.ng-select.inInputField {
	div.ng-select-container {
		background-color: #FFFFFF;
		background-color: var(--pulldown-bgcolor, #FFFFFF);

		.ng-value-container {
			.ng-value {
				.ng-value-label {
					color: #333333;
					color: var(--common-textcolor, #333333);
				}
			}

			.ng-input {
				font-size: 15px;
				font-size: var(--pulldown-textsize, 15px);

				input {
					color: #333333;
					color: var(--common-textcolor, #333333);
				}
			}
		}

		.ng-arrow-wrapper {
			.ng-arrow {
				border-color: #999999 transparent transparent transparent;
				border-color: var(--pulldown-trianglecolor, #999999) transparent transparent transparent;
			}
		}
	}

	&.ng-select-opened {
		div.ng-select-container {
			.ng-arrow-wrapper {
				.ng-arrow {
					border-color: transparent transparent #999999 transparent;
					border-color: transparent transparent var(--pulldown-trianglecolor, #999999) transparent;
				}
			}
		}
	}
}

ng-dropdown-panel.ng-dropdown-panel.inInputField {
	border-color: var(--aggrid-bordercolor);

	.ng-dropdown-panel-items {
		div {
			.ng-option {
				color: #333333;
				color: var(--pulldown-textcolor, #333333);
				background-color: #FFFFFF;
				background-color: var(--pulldown-bgcolor, #FFFFFF);

				&.ng-option-marked {
					background-color: #f5faff;
					background-color: var(--pulldown-overbgcolor, #f5faff);
				}

				&.ng-option-selected {
					background-color: #d9cde1;
					background-color: var(--pulldown-selectedbgcolor, #d9cde1);
				}
			}
		}
	}
}

ng-select.ng-select.three-point {
	width: 50px;
	min-width: 50px;
	height: 50px;
	background-color: transparent;

	.ng-select-container {
		background-color: transparent;
		width: 100%;
		height: 100% !important;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: 0;
		box-shadow: none !important;

		.ng-value-container {
			flex: none;
			width: 50px;
			height: 50px;
			padding-left: 0;
			background-image: url('/assets/img/news/btn_more_white.svg');
			background-position: center center;
			background-repeat: no-repeat;

			.ng-value {
				display: none;
			}
		}
	}

	.ng-arrow-wrapper {
		display: none;
	}

	.ng-dropdown-panel {
		top: 45px;
		left: calc(100% - 120px);
		width: 120px;
		border-radius: 10px;
		border: 0 !important;
		box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;

		&:before {
			content: "";
			width: 20px;
			height: 20px;
			top: -10px;
			background-color: white;
			display: inline-block;
			position: absolute;
			right: calc((100% - 90px) / 2);
			transform: rotate(45deg);
			box-shadow: 0 0 10px rgba(0, 0, 0, .3);
		}

		.scroll-host {
			border-radius: 10px;
		}

		.ng-option {
			height: 46px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff !important;
			font-size: 15px;
			padding: 0;

			.three-point-option {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

			&:not(:first-of-type) {
				border-top: 1px solid #ccc;
			}
		}

		.ng-option-label {
			font-weight: 400 !important;
			color: #000 !important;
		}
	}
}

.ng-select.msg-action {
	width: 30px;
	min-width: 30px;
	height: 30px;
	z-index: 1;
	background-color: transparent;


	.ng-select-container {
		background-color: transparent;
		display: flex;
		flex-direction: row;
		border: 0;
		box-shadow: none !important;

		.ng-value-container {
			width: 30px;
			height: 30px;
			padding-left: 0;
			background-image: url('/assets/img/btn_arrow_bottom.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 88%;

			.ng-value,
			.ng-input,
			.ng-placeholder {
				display: none;
			}
		}
	}

	.ng-arrow-wrapper {
		display: none;
	}

}

.msg-action.ng-dropdown-panel {
	top: 45px;
	left: calc(100% - 120px);
	width: 100px !important;
	border-radius: 10px;
	border: 0 !important;
	box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;
	transform: translate(-60px, 10px);

	&.ng-select-top {
		transform: translate(-60px, -10px);

		&:before {

			bottom: -10px;
		}
	}

	&.ng-select-bottom {
		transform: translate(-60px, 10px);

		&:before {
			top: -10px;
		}
	}

	&:before {
		content: "";
		width: 20px;
		height: 20px;
		right: 15px;
		background-color: white;
		display: inline-block;
		position: absolute;
		transform: rotate(45deg);
		box-shadow: 0 0 10px rgba(0, 0, 0, .3);
	}

	.scroll-host {
		border-radius: 10px;
	}

	.ng-option {
		height: 46px;
		display: flex !important;
		justify-content: center;
		align-items: center;
		background-color: #fff !important;
		font-size: 15px;
		padding: 0 !important;

		>.msg-action {
			display: flex;
			cursor: pointer;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
		}

		&:not(:first-of-type) {
			border-top: 1px solid #ccc;
		}
	}

	.ng-option-label {
		font-weight: 400 !important;
		color: #000 !important;
	}
}


ng-select.ng-select.target-select-tag-year {
	background-color: transparent;
	width: 230px;
	border-radius: 10px;

	.ng-value {
		font-size: 13px;
		line-height: 15px;
	}

	&.ng-select-opened {
        .ng-select-container{
            .ng-arrow {
                border-color: transparent transparent #fff;
            }
        }
	}

	&.ng-select-top{
		.ng-dropdown-panel {
			&::after{
				border-bottom: 10px solid transparent;
				border-top: 10px solid #333;
				top: 199px;
			}

			.ng-dropdown-header{
				margin-top: 0;
			}
		}
	}

	.ng-select-container {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.3) !important;
		border-color: transparent !important;
		border-radius: 10px 10px 10px 10px !important;
        cursor: pointer;
		.ng-arrow {
			border-color: #fff transparent transparent;
		}
	}

	.ng-dropdown-panel {
		background-color: transparent;
		border: 0 !important;
		border-radius: 10px;
		bottom: 40px;
		box-shadow: 0 10px 30px 0 rgb(0 0 0 / 50%);

		&::after {
			position: absolute;
			content: "";
			border: 10px solid transparent;
			border-bottom: 10px solid #333;
			top: -9px;
			left: 100px;
		}

		>.ng-dropdown-header {
            color: #FFFFFF;
			border-bottom: 0;
			font-size: 15px;
			display: flex;
			height: 40px;
			align-items: center;
			padding-left: 10px;
			background-color: #333;
			margin-top: 10px;
			border-radius: 10px 10px 0 0;
		}

		>.ng-dropdown-panel-items {
			max-height: 160px;
            background-color: #333;
            border-radius: 0 0 10px 10px;
		}

		.ng-option {
			background-color: #333 !important;
			height: 40px;
			line-height: 40px;
			font-size: 15px;
            display: flex;
            align-items: center;
			color: #ccc !important;

            fa-icon{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 22px;
                color: rgba(255, 255, 255, 0.4);
                margin-right: 8px;

                &.checked {
                    --fa-primary-color: #fff;
                    --fa-secondary-color: #e4015a;
                    --fa-primary-opacity: 1;
                    --fa-secondary-opacity: 1;
                }
            }


			&:last-child {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}