// setup breakpoints
@import "breakpoints";

.grid-container {
  --columns: 12;
  --col-gap: 20px;
  --row-gap: 20px;
  display: grid;
  --column-width: calc((100% - (var(--col-gap) * (var(--columns) - 1))) / var(--columns));
  grid-template-columns: repeat(var(--columns), var(--column-width));
  grid-auto-flow: row;
  gap: var(--row-gap) var(--col-gap);

  &.no-gap {
    gap: 0;
  }
}

// loop through breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {

    .grid-col-#{$breakpoint} {
      grid-column: span var(--columns);
    }

    @for $i from 1 through 12 {
      .grid-col-#{$breakpoint}-#{$i} {
        grid-column: span #{$i};
      }

      .grid-container.grid-columns-#{$breakpoint}-#{$i} {
        --columns: #{$i};
      }
    }
  }
}
