.qr-login{
  video{
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
  }

  #qr-shaded-region{
    &::before{
      content: " ";
      display: inline-flex;
      position: absolute;
      top: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    &::after{
      content: " ";
      display: inline-flex;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #F7E393;
    }

    > div{
      display: none;
    }
  }
}
