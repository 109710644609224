mbsc-eventcalendar {
	mbsc-calendar-view {
		&.mbsc-eventcalendar.mbsc-material-dark {
			background-color: var(--common-bgcolor, #000);
		}
		.mbsc-calendar-wrapper {
			.mbsc-calendar-body {
				.mbsc-calendar-body-inner {
					.mbsc-calendar-slide {
						mbsc-month-view {
							.mbsc-calendar-table {
								.mbsc-calendar-row {
									mbsc-calendar-day {
										.mbsc-calendar-cell-inner {
											.mbsc-calendar-cell-text {
												&[aria-pressed="true"]{
    												border-color: var(--section-maincolor);
													background-color: transparent;
													color: var(--common-textcolor);
												}
												&.mbsc-calendar-today{
													border-width: 1px;
													border-style: solid;
													background-color: var(--section-maincolor);
													border-color: var(--section-maincolor);
													color: #FFF;
												}
											
											}
											div {
												.mbsc-calendar-labels {
													mbsc-calendar-label {
														&.mbsc-calendar-label-active {
															border-width: 1px;
															border-style: solid;
															border-color: var(--section-maincolor);
															border-radius: 5px;
															overflow: hidden;
														}
													}
												}
											}
										}
									}
								}
							}
						}

					}
				}
			}
		}
	}


}

.mbsc-material{
	.mbsc-calendar-month-title{
	   color: var(--section-maincolor);
	}
	.mbsc-selected{
		.mbsc-calendar-cell-text{
			background-color: var(--section-maincolor);
			border-color: var(--section-maincolor);
		}
	}
	

}

.mbsc-eventcalendar {
	font-family: var(--common-font);
}

.calendarModulePopover {
	.mbsc-popup {
		.mbsc-popup-body {
			.mbsc-popup-content {
				.mbsc-popover-list {
					width: 364px;
					height: 348px;
					padding: 0px;
					.mbsc-colored-event {
						background-color: transparent !important;
						display: flex;
						flex-direction: row;
						padding: 20px 0px;
						margin: 0px 20px;
						border-bottom: 1px solid #ccc;
						border-radius: 0px;
						
						&.mbsc-material.mbsc-list-item.mbsc-hover:before {
							background-color: transparent;
						}
						.mbsc-event-content {

						}
						.mbsc-event-time {
							display: flex;
							flex-direction: column;
							font-size: 13px;
							justify-content: space-evenly;
							color: var(--common-textcolor2);
							.mbsc-event-sep {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}


.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-ltr, .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-ltr, .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-ltr{
	border-color:var(--calendar-line-color);

}

.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-week-day, .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day, .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day:after{
	border-color:var(--calendar-line-color);
}

.mbsc-material.mbsc-schedule-header-day.mbsc-selected {
	background-color: var(--section-maincolor);
}

.mbsc-material.mbsc-schedule-header-dayname-curr {
	color: var(--section-maincolor)
}




