@import "app/scss/variables";
@import "app/scss/breakpoints";
@import "app/scss/grid-container";

* {
  box-sizing: border-box;
}

app-root {
  > *:not(router-outlet, joy-toast) {
    flex: 1;
  }
}

.p-component {
  @extend %rainBowFont;
}

html {
  height: 100%;
  position: relative;
}

body {
  @extend %rainBowFont;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;

}

@include md {
  // change all scroll bar style to mobile like
  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    background-clip: content-box;
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 16px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px;

  &.no-padding {
    padding: 0;
  }
}

img {
  max-width: 100%;
}

a.no-style-a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}