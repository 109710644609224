$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
);


// loop through breakpoints
@each $breakpoint, $value in $breakpoints {
  @media (min-width: $value) {
    .h-#{$breakpoint}-100 {
      height: 100%;
    }
    .h-#{$breakpoint}-auto {
      height: auto;
    }
    .w-#{$breakpoint}-100 {
      width: 100%;
    }
    .w-#{$breakpoint}-auto {
      width: auto;
    }
  }
}

@mixin xs {
  @media (min-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: map-get($breakpoints, xxl)) {
    @content;
  }
}

@mixin responsive($from, $to) {
  @media (min-width: map-get($breakpoints, $from)) and (max-width: map-get($breakpoints, $to)) {
    @content;
  }
}