:host, :root {
  --color-primary: #27BAC6;
  --secondary-color: #FE654F;
  --color-success: #62AF07;
  --color-error: #FE465C;
  --color-common-text: #333333;
  --color-sub-text: #666666;
  --color-icon: #999999;
  --color-line: #cccccc;
  --color-line-light: #E2E2E2;
  --color-bookmark: #E95060;
  --color-bookmark-active: #E9506029;
  --color-menu-text: #666666;
  --color-order: #F19F0D;
  --color-disabled: #999999;
  --btn-color-enabled: #27BAC6;
  --btn-color-hover: #1F959E;
  --btn-color-active: #1F959E;
  --btn-second-bgcolor-hover: #27BAC629;
  --btn-second-bgcolor-active: #27BAC629;
  --btn-color-disable: #A9E3E8;
  --btn-primary-textcolor: #fff;
  --btn-cancel-color: #BEBEBE;
  --btn-cancel-color-hover: #A6A6A6;
  --btn-cancel-color-active: #A6A6A6;
  --color-favour: #E95060;
  --common-textcolor-helptext: #666666;
  --menubar-text-normalcolor: rgba(51, 51, 51, 1);
}

fa-icon {
  color: var(--color-icon);
  user-select: none;
}

p-button {
  background-color: var(--btn-color-enabled);
}

.section-title {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;

}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--color-primary);
}

.p-inputtext.ng-invalid {
  border-color: var(--color-error);
}

.a-link {
  color: var(--btn-color-enabled);
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  text-decoration: 1px underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

}

.p-breadcrumb {
  border: none;
  padding: 0;
  display: flex;
  height: 50px;
  align-items: center;

  .p-menuitem-link {
    .p-menuitem-text {
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      color: var(--color-sub-text);

    }

    &[href] {
      .p-menuitem-text {
        color: var(--color-primary);
        text-decoration: 1px underline;
        text-underline-offset: 2px;
      }
    }
  }

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.divider {
  border: none;
  border-top: 1px solid var(--color-line);
  margin: 32px 0 12px;

  &.line-only {
    margin: 0;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background-color: rgba(39, 186, 198, 0.2);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--color-menu-text);
}

.p-inputtext {
  &:active, &:focus, &:enabled:hover {
    border-color: var(--color-primary);
  }
  &:enabled:focus{
    box-shadow: none;
  }
}

.p-dropdown {
  &, &:active, &:focus, &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:active, &:focus, &:not(.p-disabled):hover, &:not(.p-disabled).p-focus {
    border-color: var(--color-primary);
  }
}