/*# sourceURL=perfectScroller.scss */

perfect-scrollbar {
	div.ps,
	div.ps.ps--active-x {
		width: 100% !important;
		height: 100% !important;

		div.ps__rail-x {
			background-color: transparent !important;
			height: 4px !important;
			bottom: 6px !important;

			.ps__thumb-x,
			.ps__thumb-x:hover {
				height: 4px !important;
				border-radius: 2px !important;
				background-color: rgba(0, 0, 0, 0.4) !important;
				background-color: var(--scrollbar-color, rgba(0, 0, 0, 0.4)) !important;
				bottom: 0px !important;
			}
		}
	}

	div.ps,
	div.ps.ps--active-y {
		width: 100% !important;
		height: 100% !important;

		div.ps__rail-y {
			background-color: transparent !important;
			width: 4px !important;
			right: 6px !important;

			.ps__thumb-y,
			.ps__thumb-y:hover {
				width: 4px !important;
				border-radius: 2px !important;
				//background-color: rgba(0, 0, 0, 0.2) !important;
				background-color: rgba(0, 0, 0, 0.4) !important;
				background-color: var(--scrollbar-color, rgba(0, 0, 0, 0.4)) !important;
				right: 0px !important;
			}
		}
	}
}

perfect-scrollbar.mobile {
	//height: unset;
	max-height: unset;

	.ps {
		height: 100%;
		overflow: auto !important;
		-webkit-overflow-scrolling: touch;
	}
}

perfect-scrollbar.workspace-list-row {
	&.mobile {
		::-webkit-scrollbar-thumb {
			// background-color: red;
		}
	}

	div.ps,
	div.ps.ps--active-y {
		width: 100% !important;
		height: 100% !important;

		.ps__rail-y {
			background-color: transparent !important;
			width: 4px !important;
			right: 3px !important;

			.ps__thumb-y,
			.ps__thumb-y:hover {
				width: 4px !important;
				border-radius: 2px !important;
				right: 0px !important;
			}
		}
	}
}

perfect-scrollbar.keiKokLibrary-bookshelf {
	max-height: unset;

	&.mobile {
		.ps {
			overflow: auto !important;
		}
	}

	.ps {
		height: 100%;
		overflow: hidden !important;
		-webkit-overflow-scrolling: touch;

		.ps-content {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
		}
	}
}

perfect-scrollbar.videoChat {
	div.ps,
	div.ps.ps--active-y {
		div.ps__rail-y {
			right: 2px !important;
		}
	}
}

perfect-scrollbar.ranking {
	div.ps,
	div.ps.ps--active-y {
		div.ps__rail-y {
			right: 4px !important;
		}
	}
}

perfect-scrollbar:not(.default-ps-setting).width-4 {
	max-height: unset;

	&.mobile {
		.ps {
			overflow: auto !important;
		}

		::-webkit-scrollbar {
			width: 8px;
		}
		
		::-webkit-scrollbar-track {
			background-color: transparent;
		}
		
		::-webkit-scrollbar-thumb {
			background-color: rgba(0,0,0,0.4);
			width: 8px;
			border: 1px solid transparent;
			border-radius: 4px;
			background-clip: content-box;
		}
	}

	div.ps,
	div.ps.ps--active-y {
		width: 100% !important;
		height: 100% !important;

		.ps__rail-y {
			background-color: transparent !important;
			width: 4px !important;
			right: 0 !important;
			opacity: 1 !important;

			.ps__thumb-y,
			.ps__thumb-y:hover {
				width: 4px !important;
				border-radius: 2px !important;
				right: 0px !important;
				background-color: rgba(0,0,0,0.4);
			}
		}
	}

}

ag-grid-angular .ps__rail-y{
	left: unset !important;
	opacity: 1 !important;
	z-index: 9;
	background-color: transparent !important;
	// background-color: #fff;
	// background-color: var(--common-bgcolor, #fff) !important;

	.ps__thumb-y,
	.ps__thumb-y:hover {
		width: 4px !important;
		border-radius: 2px !important;
		right: 0px !important;
		background-color: rgba(0,0,0,0.4);
		background-color: var(--aggrid-scrollbarcolor, rgba(0,0,0,0.4));
	}
}