%rainBowFont {
	font-family: "Noto Sans TC", "PingFang HK", "Heiti TC", "思源黑体", "Noto Sans CJK", "Source Han Sans", "微軟雅黑體", "微軟正黑體", "華文黑體", "宋体", sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

%user-select-all {
	-ms-user-select: text !important;
	-moz-user-select: text !important;
	-webkit-user-select: text !important;
	user-select: text !important;
}

%user-select-none {
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

%word-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

%text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

%underline{
	text-decoration: underline;
	text-decoration-line: underline;
	text-decoration-color: var(--section-maincolor, '#000');
	text-decoration-style: solid;
	text-decoration-thickness: 1px;
	text-decoration-skip-ink: none;
	-webkit-text-decoration: underline;
	-webkit-text-decoration-line: underline;
	-webkit-text-decoration-color: var(--section-maincolor, '#000');
	-webkit-text-decoration-style: solid;
	-webkit-text-decoration-thickness: 1px;
	-webkit-text-decoration-skip-ink: none;
	//padding-bottom: 1px;
    text-underline-offset: 10%;
}

%ib {
	display: inline-block;
	vertical-align: top;
}

%basicImage {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	@extend %ib;
}

%fast {
	cursor: pointer;
	// touch-action: manipulation;
}

%flex{
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
}

%inlineFlex {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 0 0 auto;
}

$errorColor: #f7434c;
$errorPlaceholderColor: #DF3354;
@mixin line-clamp($numLines : 1, $lineHeight: 1.412) {
	overflow: hidden;
	text-overflow: -o-ellipsis-lastline;
	text-overflow: ellipsis;
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: $numLines;
	-webkit-box-orient: vertical;
	max-height: $numLines * $lineHeight + unquote('em');
}

@mixin fadeColor($color, $alpha){
	background-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin text-ellipsis($lines: 1) { // ******ie not support******
    text-overflow: ellipsis;
    overflow: hidden;
	width: 100%;
    @if ($lines > 1) {
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
    } @else {
		display: inline-flex;
        white-space: nowrap;
    }
}

@mixin background-opacity($color, $opacity) {
    background: $color;  /*Fallback */
    background: rgba($color, $opacity);
}