emoji-mart {
	font-family: "Segoe UI Emoji";

	.emoji-mart-scroll {
		overflow-x: hidden;
	}

	.emoji-mart-anchors {
		padding-top: 25px;
	}

	.emoji-mart-category {
		white-space: pre-wrap;
	}

	button.emoji-mart-emoji{
		cursor: pointer !important;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		margin-right: 3px;
		margin-bottom: 3px;
		touch-action: manipulation;

		>span{
			width: auto !important;
			height: auto !important;
			display: inline !important;
			cursor: pointer !important;
		}
	}
}
